<script lang="ts">
  import { queryStore, getContextClient } from "@urql/svelte";
  import { useNavigate, useLocation } from "svelte-navigator";
  import moment from "moment";
  import type {
    DeviceList,
    DeviceNode,
    OrganizationNode,
  } from "../types/Device";
  import type { FeedCountMetrics } from "../types/FeedCountMetrics";
  import type { Organization } from "../types/Organization";
  import { Interval, allModes, allModesOldModel } from "../types/Filters";
  import SensorMap from "../components/SensorMap.svelte";
  import Highlights from "../components/Highlights.svelte";
  import Filters from "../components/Filters.svelte";
  import Loading from "./baseComponents/Loading.svelte";
  import { settingsStore, userStore } from "../stores/user";
  import { GET_DEVICE_LIST } from "../queries/getDeviceList";
  import {
    GET_COUNT_METRICS,
    feedCountTransformer,
  } from "../queries/getCountMetrics";
  import { sensorFilterStore } from "../stores/filters";
  import { deviceStore, updateBySerialNo } from "../stores/devices";
  import { tokenStore } from "../stores/user";
  import { clearAll } from "../lib/storage";
  import { trackEvent } from "../lib/analytics";
  import { groupBy } from "../lib/utils";
  import { dataVersions } from "../constants/utils";

  export let plotlyInitialized;
  let sensors: DeviceNode[] = [];
  let organization = getOrganiztion();
  $: selectedSensor = undefined;
  const startTime = moment()
    .subtract(2, "d")
    .startOf("day")
    .format("YYYY-MM-DDTHH:mm:ss");
  const endTime = moment().endOf("day").format("YYYY-MM-DDTHH:mm:ss");
  const client = getContextClient();

  const navigate = useNavigate();
  const location = useLocation();
  $: dataVersion = $settingsStore?.dataVersion;

  $: if (!$tokenStore) {
    navigate("/login", {
      state: { from: $location.pathname },
      replace: true,
    });
  }

  function logout() {
    clearAll();
    trackEvent("logout");
    navigate("/login", {
      state: { from: $location.pathname },
      replace: true,
    });
  }

  function getOrganiztion(): Organization | undefined {
    if (!$userStore || !$userStore.organization) {
      // invalid session
      logout();
    }
    return $userStore.organization;
  }

  $: deviceList = queryStore({
    client,
    query: GET_DEVICE_LIST,
    variables: { name: organization.name },
  });

  $: queryStore({
    client,
    query: GET_COUNT_METRICS,
    variables: {
      startTime,
      endTime,
      objClasses:
        dataVersion === dataVersions[0].value ? allModesOldModel : allModes,
      timezone: organization.timezone,
      interval: Interval.DAY,
      serialnos: sensors.map((s) => s.serialno),
      dataVersion,
    },
    pause: !sensors.length,
  }).subscribe(({ data, error, fetching }) => {
    if (data) {
      const transformedData = feedCountTransformer(data as FeedCountMetrics);
      let dataBySensor = groupBy(transformedData, "serialno");
      Object.keys(dataBySensor).forEach((k) => {
        const data = dataBySensor[k];
        const sensorTracks = data.reduce((acc, val) => {
          return acc + val.result;
        }, 0);
        if (sensorTracks === 0) {
          updateBySerialNo(k, { inactive: true });
        }
      });
    }
  });

  const getDeviceData = (data: DeviceList) => {
    const orgs = data.organizations.edges[0].node as OrganizationNode;
    return orgs.devices.edges
      .map((e) => e.node)
      .sort((a, b) => {
        const nameA = a.name.toUpperCase(); // ignore upper and lowercase
        const nameB = b.name.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });
  };
  $: {
    if ($deviceList.data) {
      sensors = getDeviceData($deviceList.data);
      // initialize store with first sensor
      deviceStore.set(sensors);
      sensorFilterStore.set(sensors[0]);
    }
  }
</script>

<div>
  <div class="dash-wrapper">
    <h2 class="header">{organization.name}</h2>
    <div class="divider" />
    {#if !plotlyInitialized || $deviceList.fetching}
      <div class="loading">
        <Loading />
      </div>
    {:else if $deviceList.error}
      <div class="error">
        <p>
          There was an error loading your account. <br /> Please try again
          later, and <a href="mailto:inquiries@numina.co">reach out</a> if the issue
          persists.
        </p>
      </div>
    {:else}
      <div class="row" id="highlights">
        <div class="dash-item sensors">
          <SensorMap
            on:select={(e) => (selectedSensor = e.detail.sensor)}
            {selectedSensor}
          />
        </div>
        <div class="dash-item highlights">
          {#if sensors.length}
            <Highlights
              on:select={(e) => (selectedSensor = e.detail.sensor)}
              {organization}
            />
          {/if}
        </div>
      </div>
      <div class="row" id="sensor-analysis">
        <div class="dash-item">
          <Filters {organization} {selectedSensor} />
        </div>
      </div>
    {/if}
  </div>
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .dash-wrapper {
    margin: 10px;
    max-width: 1280px;
    padding: 0 20px;

    .loading,
    .error {
      display: flex;
      justify-content: center;
      margin-top: 40px;
      text-align: center;

      a {
        color: theme.$main;
        &:hover {
          text-decoration: underline;
        }
      }
    }

    .header {
      font-size: 32px;
    }

    .row {
      display: flex;
      margin-bottom: 25px;
    }
    .dash-item {
      padding: 5px;
      width: 100%;
      margin-bottom: 20px;
    }

    .sensors {
      width: 35%;
      max-height: 500px;
    }

    .highlights {
      width: 65%;
    }

    @media only screen and (max-width: 1200px) {
      .row {
        flex-direction: column;

        .sensors {
          display: none;
        }
      }
    }
  }
</style>
