<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import type { BehaviorZoneNode } from "../../types/BehaviorZone";
  import type { DeviceNode } from "../../types/Device";
  import Button from "../baseComponents/Button.svelte";
  import { colors } from "../../constants/utils";
  import ZoneCanvas from "./ZoneCanvas.svelte";
  import Modal from "../Modal.svelte";
  import { getContextClient, mutationStore } from "@urql/svelte";
  import { CREATE_BEHAVIOR_ZONE } from "../../queries/createBehaviorZone";
  import { trackEvent } from "../../lib/analytics";
  import { UPDATE_BEHAVIOR_ZONE } from "../../queries/updateBehaviorZone";
  import { DELETE_BEHAVIOR_ZONE } from "../../queries/deleteBehaviorZone";
  import { getErrorMessage } from "../../lib/utils";

  export let zones: BehaviorZoneNode[];
  export let sensor: DeviceNode;
  export let type: "line" | "cpoly";
  let showConfirm = false;
  let zoneName;
  let editing = true;
  let errorText = "";
  $: loading = false;
  $: deletingZone = undefined;
  $: editingZone = undefined;
  $: demarcation = [];
  $: color = null;
  const client = getContextClient();
  const dispatch = createEventDispatcher();

  const colorSwatches = { ...colors };
  delete colorSwatches.bicyclist;

  function editZone(zone: BehaviorZoneNode) {
    editingZone = zone;
    demarcation = [...zone.demarcation];
    color = zone.color;
    zoneName = zone.text;
  }

  function showDeleteConfirmation(zone: BehaviorZoneNode) {
    deletingZone = zone;
    showConfirm = true;
  }

  function newZone() {
    demarcation =
      type === "cpoly"
        ? [
            [100, 100],
            [150, 100],
            [200, 100],
            [200, 150],
            [200, 200],
            [150, 200],
            [100, 200],
            [100, 150],
          ]
        : [
            [100, 100],
            [100, 200],
          ];
    color = colors.pedestrian;
    zoneName = "";
    editing = false;
  }

  function saveZone() {
    loading = true;
    if (!editing) {
      createZone();
    } else {
      updateZone();
    }
  }

  function runMutation(query, variables, callback) {
    loading = true;
    errorText = "";
    mutationStore({
      client,
      query,
      variables,
    }).subscribe(({ fetching, data, error }) => {
      if (error) {
        loading = false;
        errorText = getErrorMessage(error);
      } else if (data) {
        loading = false;
        demarcation = [];
        color = colors.pedestrian;
        zoneName = undefined;
        callback(data);
      }
    });
  }

  function createZone() {
    const callback = () => {
      trackEvent("create-bz");
      dispatch("new-zone");
      editing = true;
    };

    const variables = {
      text: zoneName,
      color,
      serialno: sensor.serialno,
      demarcation,
      zoneType: type,
    };

    runMutation(CREATE_BEHAVIOR_ZONE, variables, callback);
  }

  function updateZone() {
    const callback = (data) => {
      editingZone = undefined;
      trackEvent("edit-bz");
      dispatch("new-zone", data.updateBehaviorZone.behaviorZone.rawId);
    };

    const variables = {
      text: zoneName,
      color,
      zoneId: editingZone.rawId,
      demarcation,
    };

    runMutation(UPDATE_BEHAVIOR_ZONE, variables, callback);
  }

  function deleteZone() {
    const callback = () => {
      trackEvent("delete-bz");
      dispatch("new-zone");
      deletingZone = undefined;
      showConfirm = false;
    };

    const variables = {
      zoneId: deletingZone.rawId,
    };

    runMutation(DELETE_BEHAVIOR_ZONE, variables, callback);
  }
</script>

<div class="zone-management">
  <div class="title">
    {editing ? `${sensor.name}` : type === "line" ? "new line" : "new zone"}
  </div>
  <div class="columns">
    <div class="zones">
      {#if editing}
        {#if zones.length}
          <fieldset class="zone-list">
            <legend
              >{type === "line" ? "Screen Lines" : "Behavior Zones"}</legend
            >
            {#each zones as zone}
              <div class="zone-item">
                <div class="name">{zone.text}</div>
                <Button on:click={() => editZone(zone)} className="icon">
                  <div class="icon-btn" slot="content">
                    <img src="assets/images/edit.svg" width="20" alt="close" />
                  </div>
                </Button>
                <Button
                  on:click={() => showDeleteConfirmation(zone)}
                  className="icon"
                >
                  <div class="icon-btn" slot="content">
                    <img src="assets/images/trash.svg" width="20" alt="close" />
                  </div>
                </Button>
              </div>
            {/each}
          </fieldset>
        {/if}
        <Button style="width:200px; margin: 0 auto;" on:click={newZone}>
          <div slot="content">
            <img
              style="margin-right: 5px;"
              src="assets/images/plus.svg"
              alt="plus"
              width="15"
            />{type === "line" ? "New Line" : "New Zone"}
          </div>
        </Button>
      {/if}
      {#if demarcation.length && color}
        <div class="zone-info">
          <div class="row input">
            <label for="name">Name:</label>
            <input id="name" bind:value={zoneName} />
          </div>
          <div class="row input">
            <div class="swatches">
              {#each Object.keys(colorSwatches) as c}
                <button
                  class={"swatch " +
                    c +
                    (color === colors[c] ? " selected" : "")}
                  on:click={() => {
                    color = colors[c];
                  }}
                />
              {/each}
            </div>
          </div>
          <Button disabled={!zoneName} on:click={saveZone}
            ><div slot="content">Save</div></Button
          >
        </div>
      {/if}
    </div>
    <ZoneCanvas
      {demarcation}
      {color}
      {sensor}
      {loading}
      {errorText}
      on:move={(e) => (demarcation = e.detail.demarcation)}
    />
  </div>
</div>
{#if showConfirm}
  <Modal className="extra-small" on:close={() => (showConfirm = false)}>
    <div slot="body">
      <div class="confirm-text">
        Are you sure you want to delete {deletingZone.text}?
      </div>
      <div class="confirm-btns">
        <Button className="plain" on:click={() => (showConfirm = false)}
          ><div slot="content">No</div></Button
        >
        <Button on:click={deleteZone}><div slot="content">Yes</div></Button>
      </div>
    </div>
  </Modal>
{/if}

<style scoped lang="scss">
  @use "theme.scss";
  .zone-management {
    height: 100%;
    width: 905px;

    .title {
      margin: 5px 0;
    }
    .columns {
      display: flex;

      .zones {
        display: flex;
        flex-direction: column;
        padding: 0 5px;
        width: 270px;
        align-items: center;

        ::-webkit-scrollbar {
          -webkit-appearance: none;
          width: 7px;
        }

        ::-webkit-scrollbar-thumb {
          border-radius: 4px;
          background-color: rgba(0, 0, 0, 0.5);
          box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
        }
        .zone-list {
          border-radius: 8px;
          border-style: solid;
          border-color: theme.$main;
          width: 230px;
          max-height: 300px;
          overflow-y: scroll;
          margin: 0 auto 10px;
        }

        .zone-item {
          display: flex;
          margin: 10px 0;
          .name {
            width: 125px;
          }
        }
      }

      .zone-info {
        margin-top: 20px;
      }

      .icon-btn {
        display: flex;
        justify-content: center;
        height: 30px;

        img {
          justify-self: center;
        }
      }
    }
  }

  .confirm-text {
    text-align: center;
  }
  .confirm-btns {
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    margin-top: 20px;
  }

  .row {
    margin: 10px 2px;
    display: flex;
    justify-content: flex-start;
  }

  .input {
    width: 250px;
    justify-content: space-between;

    label {
      margin-right: 10px;
    }

    input {
      width: 90%;
    }
  }

  .swatches {
    display: flex;
  }

  .swatch {
    height: 20px;
    width: 20px;
    border-radius: 4em;
    margin: 2px 5px;
    transform: scale(1);
    transition: transform 100ms ease 0s;

    &:hover {
      cursor: pointer;
      transform: scale(1.25);
      transition: transform 100ms ease 0s;
    }

    &.pedestrian {
      background-color: theme.$ped;
      &.selected {
        filter: drop-shadow(0 0 0.25rem theme.$ped);
        border: white solid 1px;
      }
    }
    &.car {
      background-color: theme.$car;
      &.selected {
        filter: drop-shadow(0 0 0.25rem theme.$car);
        border: white solid 1px;
      }
    }

    &.truck {
      background-color: theme.$truck;
      &.selected {
        filter: drop-shadow(0 0 0.25rem theme.$truck);
        border: white solid 1px;
      }
    }

    &.bicycle {
      background-color: theme.$bike;
      &.selected {
        filter: drop-shadow(0 0 0.25rem theme.$bike);
        border: white solid 1px;
      }
    }

    &.bus {
      background-color: theme.$bus;
      &.selected {
        filter: drop-shadow(0 0 0.25rem theme.$bus);
        border: white solid 1px;
      }
    }

    &.motorbike {
      background-color: theme.$motorbike;
      &.selected {
        filter: drop-shadow(0 0 0.25rem theme.$motorbike);
        border: white solid 1px;
      }
    }
  }
</style>
