<script lang="ts">
  import type { Metric } from "src/types/Graph";
  import TimeBoxes from "../dataVisualization/TimeBoxes.svelte";
  import type { DateFilter, Mode, TimeOfDay } from "src/types/Filters";

  export let counts: Metric[];
  export let dateRange: DateFilter;
  export let modes: Mode[];
  export let timeOfDay: TimeOfDay;
  export let dayOfWeek: string;
  export let loading = true;
</script>

<div class="graph-container">
  <div class="row">
    <div class="graph-wrapper timeboxes">
      <TimeBoxes
        {counts}
        {loading}
        {dateRange}
        {modes}
        {timeOfDay}
        {dayOfWeek}
      />
    </div>
  </div>
</div>

<style lang="scss">
  .graph-container {
    display: block;
  }

  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 30px 0;
  }

  .graph-wrapper {
    &.timeboxes {
      width: 785px;
      margin-right: 50px;
    }
  }
</style>
