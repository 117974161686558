<script lang="ts">
  export let disabled = false;
  export let title = "";
</script>

<div class="wrapper">
  <label class="switch">
    <input type="checkbox" {disabled} on:change />
    <span class="slider" />
    <div class="title">{title}</div>
  </label>
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .title {
    display: flex;
    width: 100px;
    position: absolute;
    left: -10px;
  }

  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .switch input:disabled ~ .slider {
    cursor: not-allowed;
  }

  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 13px;
    bottom: 0;
    background-color: white;
    border: 0.125em solid #aaa;
    transition: 0.4s;
    border-radius: 0.75em;
    height: 1.125em;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 1px;
    background-color: #aaa;
    transition: 0.4s;
    border-radius: 50%;
  }

  input:checked + .slider {
    border: 0.125em solid theme.$main;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px theme.$main;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
    background-color: theme.$main;
  }
</style>
