<script lang="ts">
  import { getContextClient, queryStore } from "@urql/svelte";
  import { DateInput } from "date-picker-svelte";
  import Button from "./baseComponents/Button.svelte";
  import CheckGroup from "./baseComponents/CheckGroup.svelte";
  import {
    allModes,
    allModesOldModel,
    AnalysisType,
    getIntervalsForDateRange,
    Interval,
    Mode,
    TimeOfDay,
    timeOfDayMap,
    ZoneType,
  } from "../types/Filters";
  import {
    sensorFilterStore,
    dateFilterStore,
    behaviorZoneFilterStore,
    intervalFilterStore,
    modeFilterStore,
    resetFilterStores,
    addInvalidZone,
    trendsFilterStore,
    analysisTypeFilterStore,
  } from "../stores/filters";
  import type {
    BehaviorZoneList,
    BehaviorZoneNode,
  } from "../types/BehaviorZone";
  import VisualizationContainer from "./VisualizationContainer.svelte";
  import { GET_BEHAVIOR_ZONE_LIST } from "../queries/getBehaviorZoneList";
  import Modal from "./Modal.svelte";
  import ManageZones from "./behaviorZones/ManageZones.svelte";
  import {
    dateRanges,
    formatForDateRange,
    formatForViewing,
  } from "../constants/dateRanges";
  import { trackEvent } from "../lib/analytics";
  import type { Organization } from "../types/Organization";
  import Analysis from "./Analysis.svelte";
  import moment from "moment";
  import { scrollToAnchor } from "../lib/utils";
  import { deviceStore } from "../stores/devices";
  import { settingsStore } from "../stores/user";
  import { dataVersions } from "../constants/utils";

  export let organization: Organization;
  export let selectedSensor = undefined;
  $: sensors = $deviceStore;
  let sensor;
  let modelStartDate;
  $: {
    modelStartDate = sensor ? getSensorModelStartDate() : null;
  }
  let dateRange = dateRanges[2];
  let interval: Interval = Interval.DAY;
  let behaviorZones: BehaviorZoneNode[] = [];
  let screenLines: BehaviorZoneNode[] = [];
  let timeOfDay: TimeOfDay = TimeOfDay.MORNING;
  let dayOfWeek = "Weekdays";
  $: selectedModes = [];
  $: selectedZones = [];
  $: previewZone = undefined;
  $: intervals = Object.keys(Interval) as Interval[];
  $: showDatePicker = dateRange.label === "Custom";
  $: activeTab = AnalysisType.COUNTS;
  $: zoneType =
    activeTab === AnalysisType.BEHAVIORS
      ? ZoneType.POLYGON
      : ZoneType.SCREENLINE;
  $: availableModes = !!modelStartDate ? allModes : allModesOldModel;

  let showModal = false;
  let showWarning = false;
  let analysisMode = false;
  let customDate = {
    label: "Custom",
    startDate: moment().subtract(1, "w").toDate(),
    endDate: new Date(),
  };
  $: dateRangeSelections =
    activeTab === AnalysisType.TRENDS
      ? dateRanges.slice(2)
      : [...dateRanges, customDate];
  const intervalLabels = {
    "15m": "Fifteen Minutes",
    "1h": "Hourly",
    "1d": "Daily",
    "1w": "Weekly",
    "1mo": "Monthly",
    "1y": "Yearly",
  };
  const dayOfWeekSelections = ["Weekdays", "Weekends", "All Days"];

  const client = getContextClient();
  const query = GET_BEHAVIOR_ZONE_LIST;
  $: descriptor = () => {
    switch (activeTab) {
      case AnalysisType.COUNTS:
        return "Select a sensor, date range, modes and Screen Line to view count data from your sensors. Click Generate Insights to view results.";
      case AnalysisType.BEHAVIORS:
        return "Select a sensor, date range, modes and Behavior Zone to view behavior data from your sensors. Click Generate Insights to view results.";
      case AnalysisType.TRENDS:
        return "Select a time of day and days of the week to view trend data from your sensors. This will show behavior from the selected windows over the entire time range. Click Generate Insights to view results.";
      case AnalysisType.TURNS:
        return "Select a sensor, date range, modes and at least 2 Screen Lines to view turning movements. Click Generate Insights to view results.";
    }
  };
  $: variables = { serialnos: [sensor?.serialno] };

  // on changing the date range, make sure the interval is still valid
  // and on changing tabs, make sure date range is valid!
  $: {
    intervals = getIntervalsForDateRange(
      dateRange.label,
      customDate.startDate,
      customDate.endDate
    );
    if (!intervals.includes(interval) || activeTab === AnalysisType.BEHAVIORS) {
      interval = intervals[intervals.length - 1];
    }
    if (activeTab === AnalysisType.TRENDS) {
      // for trends, always use 15 min to allow analysis by time of day
      interval = Interval.FIFTEEN_MIN;
      if (!dateRangeSelections.includes(dateRange)) {
        dateRange = dateRanges[2];
      }
    }
  }

  $: {
    // if no sensor is selected, or if the selected sensor has been
    // replaced by reloading the sensor list
    // select the first sensor
    if ((!sensor && !!sensors) || !sensors.includes(sensor)) {
      sensor = sensors[0];
    }
    // on selecting a sensor from the map, set sensor
    // unless in analysis mode
    if (!analysisMode && !!selectedSensor) {
      sensor = selectedSensor;
    }
    // unset selected sensor to allow selecting from dropdown
    selectedSensor = undefined;
  }

  // subscribe to behavior zone query story to pick up changes when device is changed
  $: behaviorZoneList = queryStore({
    client,
    query,
    variables,
  });

  // on fetching new behavior zones, update filterable list
  $: {
    if (!$behaviorZoneList.fetching && !$behaviorZoneList.error) {
      const allZones =
        ($behaviorZoneList?.data as BehaviorZoneList)?.behaviorZones?.edges.map(
          (e) => e.node
        ) || [];
      behaviorZones = allZones.filter((z) => z.zoneType === "cpoly");
      screenLines = allZones.filter((z) => z.zoneType === "line");
    }
  }

  function selectModes(detail) {
    const mode = detail.value;
    const checked = detail.checked;

    if (mode === "ALL") {
      if (checked) {
        selectedModes = [...availableModes];
      } else {
        selectedModes = [];
      }
      return;
    }
    const idx = selectedModes.indexOf(mode);
    if (idx > -1) {
      selectedModes.splice(idx, 1);
    } else {
      selectedModes.push(mode);
    }
    selectedModes = selectedModes;
  }

  function selectZones(zone) {
    const idx = selectedZones.indexOf(zone);
    if (selectedZones.indexOf(zone) > -1) {
      selectedZones.splice(idx, 1);
    } else {
      selectedZones.push(zone);
    }
    selectedZones = selectedZones;
  }

  function getSensorModelStartDate() {
    try {
      const tags = JSON.parse(sensor.tags);
      if (tags.model && tags.model.models && tags.model.current) {
        const currentModel = tags.model.models[tags.model.current];
        return currentModel.start_date;
      }
      return null;
    } catch (e) {
      // no tags / invalid tags
      return null;
    }
  }

  function getInsights() {
    // make sure the query doesn't span multiple models
    if (
      modelStartDate &&
      moment(dateRange.startDate).isBefore(modelStartDate) &&
      moment(dateRange.endDate).isAfter(modelStartDate)
    ) {
      // if the date range spans multiple models, show a warning and return
      showWarning = true;
      return;
    }
    // set all insights filters in the store to trigger queries
    behaviorZoneFilterStore.set(selectedZones);
    intervalFilterStore.set(interval);
    modeFilterStore.set(selectedModes);
    sensorFilterStore.set(sensor);
    analysisTypeFilterStore.set(activeTab);
    if (dateRange.label === "Custom") {
      const dateRangeFilter = {
        label: dateRange.label,
        startDate: formatForDateRange(dateRange.startDate, true),
        endDate: formatForDateRange(dateRange.endDate, false),
      };
      dateFilterStore.set(dateRangeFilter);
    } else {
      dateFilterStore.set(dateRange);
    }
    if (activeTab === AnalysisType.TRENDS) {
      trendsFilterStore.set({
        timeOfDay,
        dayOfWeek,
      });
    }
    trackEvent("get-insights", {
      sensor: sensor.serialno,
      modes: selectedModes,
      interval,
      dateRange,
      behaviorZones: selectedZones.map((z) => z.id),
    });
    analysisMode = true;
    setTimeout(() => scrollToAnchor("analysis"), 100);
  }

  function clearFilters(reset = false) {
    resetFilterStores(sensor);
    if (reset) {
      selectedModes = [];
      selectedZones = [];
    }
    analysisMode = false;
  }

  function refreshZones(zoneID?: string) {
    // on creating a new behavior zone refresh the list from the API
    queryStore({
      client,
      query,
      variables,
      requestPolicy: "network-only",
    });
    selectedZones = [];
    if (zoneID) {
      // if a zone was edited, store it in the invalidatedZones store
      // so we know to query with network only
      addInvalidZone(zoneID);
    }
  }

  $: canGenerateInsights = !!(
    selectedModes.length > 0 &&
    dateRange?.startDate &&
    dateRange?.endDate &&
    (activeTab !== AnalysisType.TURNS || selectedZones.length > 1)
  );

  function getDateLabel() {
    if (dateRange.label === "Custom") {
      return (
        formatForViewing(dateRange.startDate) +
        " - " +
        formatForViewing(dateRange.endDate)
      );
    }
    return dateRange.label;
  }

  function toggleTab(tab: AnalysisType) {
    if (tab !== activeTab) {
      selectedZones = [];
      activeTab = tab;
    }
  }
</script>

<div>
  <div class="tab-row">
    <h2>Sensor Analysis</h2>
    <div class="tab-divider divider" />
    <button
      class="tab"
      class:active={activeTab === AnalysisType.COUNTS}
      on:click={() => toggleTab(AnalysisType.COUNTS)}
      disabled={analysisMode}
    >
      Counts
    </button>
    <button
      class="tab"
      class:active={activeTab === AnalysisType.BEHAVIORS}
      on:click={() => toggleTab(AnalysisType.BEHAVIORS)}
      disabled={analysisMode}
    >
      Behavior
    </button>
    <button
      class="tab"
      class:active={activeTab === AnalysisType.TRENDS}
      on:click={() => toggleTab(AnalysisType.TRENDS)}
      disabled={analysisMode}
    >
      Trends
    </button>
    <button
      class="tab"
      class:active={activeTab === AnalysisType.TURNS}
      on:click={() => toggleTab(AnalysisType.TURNS)}
      disabled={analysisMode}
    >
      Turns
    </button>
    <span class="tooltip">Click "Edit Filters" to switch tabs</span>
  </div>
  {#if sensor}
    <div class="insight-wrapper">
      <div class="filters">
        <p>
          {descriptor()}
          {#if activeTab === AnalysisType.BEHAVIORS}
            <br />
            <strong>Note:</strong> For the most accurate volume data, please use
            Screen Lines instead of Behavior Zones.
          {/if}
        </p>
        {#if !analysisMode}
          <div class="inputs">
            <div class="dropdowns">
              <div class="selector-row">
                <label for="sensor">Sensor:</label>
                <select
                  bind:value={sensor}
                  name="sensor"
                  id="sensor"
                  on:change={() => clearFilters(true)}
                >
                  {#each sensors as s}
                    <option value={s}
                      >{s.name + (s.inactive ? " - Inactive" : "")}</option
                    >
                  {/each}
                </select>
              </div>
              {#if sensor.inactive}
                <div class="selector-row note">
                  This sensor has not collected any data for the past 48 hours
                  and may be offline.
                </div>
              {/if}
              {#if modelStartDate && moment(modelStartDate).diff(moment(), "months") < 1}
                <div class="selector-row note">
                  This sensor was upgraded with a new model. To view data from
                  this sensor, please ensure your date range falls entirely
                  before or after {moment(modelStartDate).format(
                    "MMMM Do YYYY"
                  )}
                </div>
              {/if}
              <div class="selector-row">
                <label for="date">Date Range:</label>
                <select bind:value={dateRange} name="date" id="date">
                  {#each dateRangeSelections as d}
                    <option value={d}>{d.label}</option>
                  {/each}
                </select>
              </div>
              {#if showDatePicker}
                <div class="selector-row">
                  <label for="date-input">Start: </label>
                  <DateInput
                    closeOnSelection={true}
                    max={new Date()}
                    format="yyyy-MM-dd"
                    bind:value={customDate.startDate}
                  />
                </div>
                <div class="selector-row">
                  <label for="date-input">End: </label>
                  <DateInput
                    closeOnSelection={true}
                    min={customDate.startDate}
                    max={new Date()}
                    format="yyyy-MM-dd"
                    bind:value={customDate.endDate}
                  />
                </div>
              {/if}
              {#if activeTab === AnalysisType.COUNTS || activeTab === AnalysisType.TURNS}
                <!-- only allow selecting intervals for counts and turns -->
                <div class="selector-row">
                  <label for="interval">Interval:</label>
                  <select bind:value={interval} name="interval" id="interval">
                    {#each intervals as i}
                      <option value={i}>{intervalLabels[i]}</option>
                    {/each}
                  </select>
                </div>
              {/if}
              {#if activeTab === AnalysisType.TRENDS}
                <!-- only allow time of day/week selections for trends -->
                <div class="selector-row">
                  <label for="time-of-day">Time of Day:</label>
                  <select
                    bind:value={timeOfDay}
                    name="time-of-day"
                    id="time-of-day"
                  >
                    {#each Object.keys(timeOfDayMap) as t}
                      <option value={t}
                        >{timeOfDayMap[t].name +
                          " - " +
                          timeOfDayMap[t].label}</option
                      >
                    {/each}
                  </select>
                </div>
                <div class="selector-row">
                  <label for="day-of-week">Days of Week:</label>
                  <select
                    bind:value={dayOfWeek}
                    name="day-of-week"
                    id="day-of-week"
                  >
                    {#each dayOfWeekSelections as d}
                      <option value={d}>{d}</option>
                    {/each}
                  </select>
                </div>
              {/if}
            </div>
            <div class="selector-row checks">
              <div class="check-row-item">
                <CheckGroup
                  title="Modes:"
                  items={availableModes.map((m) => {
                    return {
                      mode: m,
                    };
                  })}
                  valueKey="mode"
                  nameKey="mode"
                  classKey="mode"
                  selectAll={true}
                  checkedVals={selectedModes}
                  on:change={(e) => selectModes(e?.detail)}
                />
              </div>
              <div class="check-row-item">
                <CheckGroup
                  title={activeTab === AnalysisType.BEHAVIORS
                    ? "Behavior Zones:"
                    : "Screen Lines:"}
                  items={activeTab === AnalysisType.BEHAVIORS
                    ? behaviorZones
                    : screenLines}
                  nameKey="text"
                  checkedVals={selectedZones}
                  on:change={(e) => selectZones(e.detail.value)}
                  on:mouseenter={(e) => {
                    previewZone = e.detail.value;
                  }}
                  on:mouseleave={() => {
                    previewZone = undefined;
                  }}
                />
                <div class="manage-zones">
                  <Button on:click={() => (showModal = true)}
                    ><div slot="content">
                      {activeTab === AnalysisType.BEHAVIORS
                        ? "Manage Zones"
                        : "Manage Lines"}
                    </div></Button
                  >
                </div>
              </div>
            </div>
          </div>
        {:else}
          <div class="displays">
            <div class="filter-text">
              <span class="line">
                Sensor: <span class="filter-value">{sensor.name}</span>
              </span>
              <span class="line">
                Date Range: <span class="filter-value">{getDateLabel()}</span>
              </span>
              {#if activeTab === AnalysisType.COUNTS || activeTab === AnalysisType.TURNS}
                <span class="line">
                  Interval: <span class="filter-value"
                    >{intervalLabels[interval]}</span
                  >
                </span>
              {/if}
              {#if activeTab === AnalysisType.TRENDS}
                <span class="line">
                  Days of Week: <span class="filter-value">{dayOfWeek}</span>
                </span>
                <span class="line">
                  Time of Day: <span class="filter-value"
                    >{timeOfDayMap[timeOfDay].name +
                      " - " +
                      timeOfDayMap[timeOfDay].label}</span
                  >
                </span>
              {/if}
            </div>

            <div class="selector-row checks">
              <div class="check-row-item">
                <CheckGroup
                  title="Modes:"
                  items={availableModes.map((m) => {
                    return {
                      mode: m,
                    };
                  })}
                  valueKey="mode"
                  nameKey="mode"
                  classKey="mode"
                  checkedVals={selectedModes}
                  disabled={true}
                />
              </div>
              <div class="check-row-item">
                <CheckGroup
                  title={activeTab === AnalysisType.BEHAVIORS
                    ? "Behavior Zones:"
                    : "Screen Lines:"}
                  items={activeTab === AnalysisType.BEHAVIORS
                    ? behaviorZones
                    : screenLines}
                  nameKey="text"
                  checkedVals={selectedZones}
                  disabled={true}
                  on:mouseenter={(e) => {
                    previewZone = e.detail.value;
                  }}
                  on:mouseleave={() => {
                    previewZone = undefined;
                  }}
                />
              </div>
            </div>
          </div>
        {/if}
        <div class="generate-insights">
          <Button
            on:click={analysisMode ? () => clearFilters() : getInsights}
            disabled={!canGenerateInsights}
          >
            <div class="btn" slot="content">
              {analysisMode ? "Edit Filters" : "Generate Insights"}
            </div>
          </Button>
        </div>
      </div>
      <div class="visualization">
        <VisualizationContainer
          {previewZone}
          {sensor}
          {organization}
          behaviorZones={selectedZones}
          {zoneType}
          analysisType={activeTab}
        />
      </div>
    </div>
  {/if}
</div>
<Analysis {organization} {zoneType} />
{#if showModal}
  <Modal on:close={() => (showModal = false)}>
    <h2 class="modal-header" slot="header">
      Manage {activeTab === AnalysisType.BEHAVIORS
        ? "Behavior Zones"
        : "Screen Lines"}
    </h2>

    <div slot="body">
      <ManageZones
        on:close={() => (showModal = false)}
        on:new-zone={(e) => refreshZones(e.detail)}
        zones={activeTab === AnalysisType.BEHAVIORS
          ? behaviorZones
          : screenLines}
        type={activeTab === AnalysisType.BEHAVIORS ? "cpoly" : "line"}
        {sensor}
      />
    </div>
  </Modal>
{/if}
{#if showWarning}
  <Modal on:close={() => (showWarning = false)}>
    <h2 class="modal-header" slot="header">Invalid Query</h2>
    <div slot="body" style="max-width: content">
      <p style="text-align: center;">
        This sensor has been updated with a new model. To view data from this
        sensor, please ensure your date range falls entirely before or after {moment(
          modelStartDate
        ).format("MMMM Do YYYY")}
      </p>
    </div>
  </Modal>
{/if}

<style lang="scss">
  @use "theme.scss";

  .tab-row {
    display: flex;
    align-items: flex-end;
    position: relative;
    .tooltip {
      position: absolute;
      visibility: hidden;
      width: 250px;
      font-size: 14px;
      font-family: Inter;
      text-align: center;
      padding: 5px 0;
      border-radius: 6px;
      border: 1px solid theme.$main;
      position: absolute;
      z-index: 10;
      background-color: white;
      top: -30px;
      left: 200px;
    }
    .tab:disabled:hover ~ .tooltip {
      visibility: visible;
    }

    h2 {
      margin: 0 10px 5px 0;
    }

    .tab-divider {
      position: absolute;
      bottom: -10px;
    }
    .tab {
      border-radius: 4px 4px 0 0;
      border: 1px solid theme.$black;
      background-color: white;
      height: 40px;
      width: 100px;
      position: relative;
      margin: 0 2px;
      border-bottom: 1px solid theme.$black;

      &::after {
        content: "";
        position: absolute;
        bottom: 7px;
        left: 5px;
        right: 5px;
        height: 0.2em;
        background-color: theme.$main;
        transition: opacity 300ms, transform 300ms;
        opacity: 1;
        transform: scale(0);
        transform-origin: center;
      }

      &:hover {
        background-color: theme.$lightest-background;
      }

      &.active::after {
        transform: translate3d(0, 0.2em, 0);
        transform: scale(1);
      }

      &.active {
        transition: ease-in-out 0.2s;
        border-bottom: 1px solid white;
      }

      &:disabled {
        background-color: white;
        cursor: initial;
      }
    }
  }
  .filter-text {
    height: 112px;
    .line {
      display: block;
    }
    .filter-value {
      font-weight: bold;
    }
  }

  .inputs,
  .displays {
    min-height: 470px;
    background-color: theme.$gray;
    border-radius: 8px;
    padding: 10px;
  }

  .insight-wrapper {
    display: flex;
    justify-content: space-between;

    .filters {
      width: 550px;
      border-radius: 8px;
    }
    .filter-text {
      margin: 10px 5px;
      padding: 0 20px;
    }
    .note {
      font-style: thin;
      font-size: 14px;
    }

    .selector-row {
      margin: 10px 5px 20px;
      display: flex;
      padding: 0 20px;

      label {
        width: 150px;
      }

      select {
        width: 300px;
        border: none;
        font-family: Inter;
        box-shadow: none;
        border-radius: 8px;

        &:hover,
        &:focus,
        &:active {
          outline: 2px solid theme.$main !important;
          border-radius: 8px;
        }
      }

      &.checks {
        margin-top: 28px;

        .check-row-item {
          margin: 0 10px;
        }
      }

      .manage-zones {
        margin: 5px;
        display: flex;
      }
    }

    .generate-insights {
      display: flex;
      justify-content: center;
      margin: 30px;

      .btn {
        width: 400px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .insight-wrapper {
      flex-direction: column;
      align-items: center;
    }
  }
</style>
