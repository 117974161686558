<script lang="ts">
  import type { Organization } from "../types/Organization";
  import type { DeviceNode } from "../types/Device";
  import { deviceStore } from "../stores/devices";
  import { downloadFile, formatLocation } from "../lib/utils";
  import Button from "./baseComponents/Button.svelte";
  import Papa from "papaparse";
  import { createEventDispatcher } from "svelte";
  import Modal from "./Modal.svelte";
  import SensorModal from "./SensorModal.svelte";

  export let organization: Organization;
  $: sensors = $deviceStore;
  $: loadingCsvExport = false;
  $: viewModal = false;
  const dispatch = createEventDispatcher();

  function exportCsv() {
    loadingCsvExport = true;
    const csvJson = sensors.map((sensor) => {
      return {
        name: encodeURI(sensor.name), // encodeURI to handle spaces in sensor names
        serialno: sensor.serialno,
        location: formatLocation(sensor),
      };
    });
    const csv = Papa.unparse(csvJson, { newline: "%0A" });
    downloadFile(
      `data:attachment/csv,${csv}`,
      `${organization.name}-Numina-Sensors.csv`
    );
    loadingCsvExport = false;
  }

  function selectSensor(sensor: DeviceNode) {
    dispatch("select", { sensor });
  }

  // function updateHighlights({ detail }) {
  //   let totalTracks = 0;
  //   let highestSensorCount = 0;
  //   let highestSensor;
  //   Object.keys(detail).forEach((k) => {
  //     const data = detail[k];
  //     const sensorTracks = data.reduce((acc, val) => {
  //       return acc + val.result;
  //     }, 0);
  //     totalTracks += sensorTracks;
  //     if (sensorTracks > highestSensorCount) {
  //       highestSensorCount = sensorTracks;
  //       highestSensor = k;
  //     }
  //   });
  //   if (highestSensor) {
  //     highlightData = {
  //       totalTracks: numberWithCommas(totalTracks),
  //       highestSensorCount: numberWithCommas(highestSensorCount),
  //       highestSensor: getDeviceName(highestSensor),
  //     };
  //   } else {
  //     highlightData = {};
  //   }
  // }

  // function numberWithCommas(num: number) {
  //   return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }

  // function getDeviceName(serialno: string) {
  //   if (!serialno) {
  //     return undefined;
  //   }
  //   return (
  //     $deviceStore.find((d) => d.serialno === serialno)?.name ||
  //     "Unknown Sensor"
  //   );
  // }
</script>

<h2>Sensor Information</h2>
<div class="content">
  <div class="highlight-text">
    {organization.name} has {$deviceStore.length} sensors.
    <!-- {#if highlightData}
      {#if highlightData.totalTracks}
        In the past month, {highlightData.totalTracks} counts have been collected.
        The sensor with the most counts is
        <span class="green">{highlightData.highestSensor}</span>
        with {highlightData.highestSensorCount}
        counts.
      {:else}
        No Sensors have reported data yet
      {/if}
    {/if} -->
    <div style="margin-left: auto;">
      <Button className="link" on:click={() => (viewModal = true)}>
        <div slot="content">Sensor Details</div>
      </Button>
    </div>
  </div>
  <!-- <div class="row graph-wrapper">
    <ModeShare {organization} on:sensorData={updateHighlights} />
  </div> -->
  <div class="device-table">
    <div class="list">
      <table>
        <colgroup>
          <col span="1" style="width: 50%;" />
          <col span="1" style="width: 50%;" />
        </colgroup>
        <tr>
          <th>Name</th>
          <th>Location</th>
        </tr>
        {#each sensors as sensor}
          <tr on:click={() => selectSensor(sensor)}>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <td>{sensor.name}</td>
            <!-- svelte-ignore a11y-click-events-have-key-events -->
            <td>{formatLocation(sensor, true)}</td>
          </tr>
        {/each}
      </table>
    </div>
  </div>
</div>
{#if viewModal}
  <Modal on:close={() => (viewModal = false)}>
    <h2 class="modal-header" slot="header">
      Sensor Info
      <Button
        on:click={exportCsv}
        loading={loadingCsvExport}
        style={"font-size: 14px; max-height: 40px; margin-left: 10px;"}
      >
        <div slot="content">export CSV</div>
      </Button>
    </h2>
    <div slot="body">
      <SensorModal
        on:close={() => (viewModal = false)}
        on:selectSensor={() => selectSensor}
        {sensors}
      />
    </div>
  </Modal>
{/if}

<style scoped lang="scss">
  @use "theme.scss";
  .content {
    display: flex;
    flex-direction: column;
    width: 800px;
    height: 400px;
  }

  .highlight-text {
    margin-bottom: 10px;
    display: flex;
  }
  .modal-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  // .green {
  //   color: theme.$main;
  //   font-weight: bold;
  // }
  // .row {
  //   padding: 5px;
  //   display: flex;
  //   justify-content: space-between;
  // }

  .device-table {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 358px;
    .list {
      overflow-y: scroll;
      width: 100%;

      table {
        border-spacing: 2px;
        width: 100%;
        tr {
          cursor: pointer;
          height: 40px;
          text-align: center;
          width: 50%;

          &:nth-child(odd) {
            background-color: theme.$light-background;
          }
        }
        th {
          cursor: default;
          position: sticky;
          top: 0; /* Don't forget this, required for the stickiness */
          background-color: theme.$main;
          color: white;
        }
      }
    }
  }
</style>
