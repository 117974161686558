import { gql } from "@urql/svelte";

export const GET_DEVICE_LIST = gql`
  query GetDeviceList($name: String) {
    organizations(name: $name) {
      edges {
        node {
          id
          name
          devices {
            edges {
              node {
                id
                name
                alias
                serialno
                feed {
                  id
                }
                location {
                  id
                  lat
                  lon
                  azi
                }
                tags
              }
            }
          }
        }
      }
    }
  }
`;
