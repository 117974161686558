import { gql } from "@urql/svelte";
import type { FeedHeatMaps } from "src/types/FeedHeatMaps";

export const GET_HEAT_MAP = gql`
  query FeedHeatMap(
    $serialno: String!
    $objClasses: [String]!
    $startTime: DateTime!
    $endTime: DateTime!
    $timezone: String
    $samplePercent: Float
    $days: [Int]
    $window: TrendWindowEnum
    $dataVersion: DataVersionEnum
  ) {
    feedHeatmaps(
      serialno: $serialno
      objClasses: $objClasses
      startTime: $startTime
      endTime: $endTime
      timezone: $timezone
      samplePercent: $samplePercent
      days: $days
      window: $window
      dataVersion: $dataVersion
    ) {
      edges {
        node {
          serialno
          objClass
          heatmap
          time
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const feedHeatmapTransformer = (data: FeedHeatMaps) => {
  return data?.feedHeatmaps?.edges?.map((e) => e.node) || [];
};
