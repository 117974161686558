import { gql } from "@urql/svelte";
import type { ScreenLineCountMetrics } from "../types/ScreenLineCountMetrics";

export const GET_SCREEN_LINE_COUNT_METRICS = gql`
  query screenlineCountMetrics(
    $zoneIds: [Int]!
    $startTime: DateTime!
    $endTime: DateTime!
    $interval: String
    $objClasses: [String]!
    $timezone: String
    $dataVersion: DataVersionEnum
  ) {
    screenlineCountMetrics(
      zoneIds: $zoneIds
      startTime: $startTime
      endTime: $endTime
      interval: $interval
      objClasses: $objClasses
      timezone: $timezone
      dataVersion: $dataVersion
    ) {
      edges {
        node {
          id
          metric
          objClass
          result
          time
        }
      }
    }
  }
`;

export const screenLineCountTransformer = (data: ScreenLineCountMetrics) => {
  return data?.screenlineCountMetrics?.edges?.map((e) => e.node) || [];
};
