<script lang="ts">
  import { onMount } from "svelte";
  import type { FeedHeatMapNode } from "../../types/FeedHeatMaps";
  import type { BehaviorZoneNode } from "../../types/BehaviorZone";
  import { plotlyStore } from "../../stores/plotly";
  import { CONFIG, HEATMAP_LAYOUT } from "../../constants/plotlyLayouts";
  import {
    Mode,
    ZoneType,
    type DateFilter,
    AnalysisType,
  } from "../../types/Filters";
  import Button from "../baseComponents/Button.svelte";
  import Tooltip from "../baseComponents/Tooltip.svelte";
  import { exportImage, loadImage } from "../../lib/utils";
  import { sampleImageUrl } from "../../constants/utils";
  import { formatForViewing } from "../../constants/dateRanges";
  import type { DeviceNode } from "../../types/Device";

  export let heatMaps: FeedHeatMapNode[];
  export let behaviorZones: BehaviorZoneNode[] = [];
  export let showHeatMap = null;
  export let shouldShowAnalysis = false;
  export let previewZone: BehaviorZoneNode | undefined = undefined;
  export let analysisType: AnalysisType;
  export let zoneType: ZoneType;
  export let modes: Mode[];
  export let dateRange: DateFilter;
  export let sensor: DeviceNode;

  let maskCtx;
  let mask;
  let Plotly = $plotlyStore;
  let heatMapDiv;
  let desireLineDiv;
  let rendered = false;
  let tooltip;
  const activeButtonStyle =
    "color: #56bb80; outline: 1px solid #56bb80; background-color: rgba(86, 187, 128, 0.2);";
  $: {
    if (
      analysisType === AnalysisType.BEHAVIORS ||
      analysisType === AnalysisType.TRENDS
    ) {
      tooltip =
        "Use the Heat Map buttons to view object density per mode. Use the mode toggles to hide and show a mode's desire lines.";
    } else {
      tooltip = "Use the mode toggles to hide and show a mode's desire lines.";
    }
  }
  $: sampleImgUrl = sampleImageUrl(sensor?.serialno || "");
  $: hiddenModes = [];
  let parsedMaps = {};

  const colorscales = {
    pedestrian: [
      [0, "rgba(47, 186, 120, 0.2)"],
      [0.5, "rgba(47, 186, 120, 0.5)"],
      [1, "rgba(47, 186, 120, 1)"],
    ],
    bicycle: [
      [0, "rgba(15, 167, 240, 0.2)"],
      [0.5, "rgba(15, 167, 240, 0.5)"],
      [1, "rgba(15, 167, 240, 1)"],
    ],
    car: [
      [0, "rgba(250, 104, 77, 0.2)"],
      [0.5, "rgba(250, 104, 77, 0.5)"],
      [1, "rgba(250, 104, 77, 1)"],
    ],
    bus: [
      [0, "rgba(134, 60, 246, 0.2)"],
      [0.5, "rgba(134, 60, 246, 0.5)"],
      [1, "rgba(134, 60, 246, 1)"],
    ],
    truck: [
      [0, "rgba(209, 35, 47, 0.2)"],
      [0.5, "rgba(209, 35, 47, 0.5)"],
      [1, "rgba(209, 35, 47, 1)"],
    ],
    motorbike: [
      [0, "rgba(255, 102, 255, 0.2)"],
      [0.5, "rgba(255, 102, 255, 0.5)"],
      [1, "rgba(255, 102, 255, 1)"],
    ],
    van: [
      [0, "rgba(140, 85, 22, 0.2)"],
      [0.5, "rgba(140, 85, 22, 0.5)"],
      [1, "rgba(140, 85, 22, 1)"],
    ],
    dog: [
      [0, "rgba(45, 113, 159, 0.2)"],
      [0.5, "rgba(45, 113, 159, 0.5)"],
      [1, "rgba(45, 113, 159, 1)"],
    ],
    kickboard: [
      [0, "rgba(247, 179, 43, 0.2)"],
      [0.5, "rgba(247, 179, 43, 0.5)"],
      [1, "rgba(247, 179, 43, 1)"],
    ],
  };
  const heatmapColorscale = [
    [0, "rgba(247, 157, 90, 0.7)"],
    [0.5, "rgba(225, 65, 25, 0.7)"],
    [1, "rgba(188, 0, 41, 0.7)"],
  ];
  $: {
    if (shouldShowAnalysis && heatMapDiv && heatMaps?.length) {
      if (!showHeatMap) {
        Plotly.purge(heatMapDiv);
        makeDesireLines(hiddenModes, behaviorZones);
      } else {
        Plotly.purge(desireLineDiv);
        makeHeatMap();
      }
    } else if (heatMapDiv && !shouldShowAnalysis) {
      Plotly.purge(desireLineDiv);
      clearMask();
      Plotly.purge(heatMapDiv);
      // reset hidden modes
      hiddenModes = [];
      // reset parsed maps
      parsedMaps = {};
      // go back to desireline mode
      showHeatMap = false;
    }
  }
  $: {
    // if currently viewing analysis
    if (shouldShowAnalysis) {
      // if the current canvas is rendered
      if (!!maskCtx && !!mask) {
        // if hovering over a zone
        if (!!previewZone) {
          drawMask([previewZone]);
          // if leaving hover
        } else if (!previewZone) {
          drawMask(behaviorZones);
        }
      }
    }
  }
  onMount(() => {
    maskCtx = mask.getContext("2d");
  });
  function drawMask(zones) {
    clearMask();
    if (zones.length > 0) {
      if (zoneType === ZoneType.SCREENLINE) {
        // draw the screenline
        maskCtx.globalAlpha = 1;
        for (let zone of zones) {
          const points = zone.demarcation;
          maskCtx.strokeStyle = "#333333";
          maskCtx.lineWidth = 4;
          maskCtx.beginPath();
          maskCtx.moveTo(...points[0]);
          maskCtx.lineTo(...points[1]);
          maskCtx.stroke();
        }
      } else {
        maskCtx.fillStyle = "#000";
        maskCtx.globalAlpha = 0.5;
        maskCtx.fillRect(0, 0, mask.width, mask.height);
        for (let zone of zones) {
          maskCtx.globalAlpha = 1;
          maskCtx.globalCompositeOperation = "destination-out";
          const dem = [...zone.demarcation];
          maskCtx.strokeStyle = "#0FA7F0";
          maskCtx.beginPath();
          maskCtx.moveTo(...dem[0]);
          dem.slice(1, dem.length).forEach((p) => {
            maskCtx.lineTo(...p);
            maskCtx.stroke();
          });
          maskCtx.lineTo(...dem[0]);
          maskCtx.closePath();
          maskCtx.fill();
          maskCtx.globalCompositeOperation = "source-over";
        }
      }
    }
  }
  function clearMask() {
    maskCtx.clearRect(0, 0, mask.width, mask.height);
  }
  function makeDesireLines(hiddenModes, behaviorZones) {
    if (Object.keys(parsedMaps).length === 0) {
      // parse the maps if not already done
      parseMaps(heatMaps);
    }
    const compare = (a, b) => {
      // order the maps so that the largest maps get drawn first
      if (a.mapLen > b.mapLen) {
        return -1;
      } else if (a.mapLen < b.mapLen) {
        return 1;
      } else {
        return 0;
      }
    };
    const traces = [];
    const mapsToShow = Object.keys(parsedMaps)
      .filter((k) => !hiddenModes.includes(k))
      .map((k) => {
        return { map: parsedMaps[k], objClass: k };
      });
    mapsToShow.sort(compare).forEach((map) => {
      const trace_heatmap = makeTrace(map.map, colorscales[map.objClass]);
      traces.push(trace_heatmap);
    });
    render(desireLineDiv, traces);
    if (behaviorZones.length > 0) {
      drawMask(behaviorZones);
    } else {
      clearMask();
    }
  }

  function makeHeatMap() {
    if (Object.keys(parsedMaps).length === 0) {
      // parse the maps if not already done
      parseMaps(heatMaps);
    }
    const map = parsedMaps[showHeatMap];
    const trace_heatmap = makeTrace(map, heatmapColorscale);
    render(heatMapDiv, [trace_heatmap]);
    if (behaviorZones.length > 0) {
      drawMask(behaviorZones);
    }
  }

  function parseMaps(heatmaps) {
    // parse the maps into a format that plotly can use, filling all pixels that are not in the heatmap with NaN
    heatmaps.forEach((map) => {
      const heatmap = map["heatmap"];
      const x = heatmap.map((d) => d[0]);
      const y = heatmap.map((d) => d[1]);
      const z = heatmap.map((d) => d[2]);
      // save actual size of map for sorting
      const mapLen = x.length;
      if (mapLen < 700) {
        // fill all pixels in smaller maps to avoid weird plotly errors
        for (let i = 0; i < 640; i++) {
          for (let j = 0; j < 480; j++) {
            if (!heatmap.find((item) => item[0] === i && item[1] === j)) {
              x.push(i);
              y.push(j);
              z.push(NaN);
            }
          }
        }
      }
      parsedMaps[map.objClass] = { x, y, z, mapLen };
    });
  }

  function makeTrace(map, colorscale) {
    return {
      name: "Intensity",
      x: map.x,
      y: map.y,
      z: map.z,
      type: "heatmap",
      xaxis: "x",
      yaxis: "y",
      showscale: false,
      colorscale,
    };
  }
  function render(div, traces) {
    if (rendered) {
      Plotly.react(div, traces, HEATMAP_LAYOUT, CONFIG);
    } else {
      Plotly.newPlot(div, traces, HEATMAP_LAYOUT, CONFIG);
      rendered = true;
    }
  }

  function toggleShowHeatMap(mode: Mode | null) {
    showHeatMap = mode;
    hiddenModes = [];
  }
  function toggleModes(mode: Mode) {
    if (showHeatMap) {
      // if viewing heatmaps, select this mode
      showHeatMap = mode;
      return;
    }
    // otherwise, toggle the mode for desire lines
    const idx = hiddenModes.indexOf(mode);
    if (idx > -1) {
      hiddenModes.splice(idx, 1);
    } else {
      hiddenModes.push(mode);
    }
    hiddenModes = hiddenModes;
  }

  async function exportHeatmapImage() {
    const div = document.querySelector("#heat-map") as HTMLElement;
    const bgImage = await loadImage(sampleImgUrl);
    const filename = `heatmap${
      showHeatMap ? `_${showHeatMap}` : ""
    }_${formatForViewing(dateRange.startDate)}_${formatForViewing(
      dateRange.endDate
    )}`;
    exportImage(div, filename, bgImage);
  }
</script>

<div class="wrapper">
  <div class="tools-wrapper">
    <div class="tools">
      {#if shouldShowAnalysis && analysisType !== AnalysisType.COUNTS}
        <div class="swatches">
          <Button
            className="plain"
            disabled={Object.keys(parsedMaps).length === 0}
            style={"border-radius: 4px 0 0 4px;margin-right: 2px;" +
              (!showHeatMap
                ? activeButtonStyle
                : "outline: 1px solid #f4f4f4;")}
            on:click={() => toggleShowHeatMap(null)}
          >
            <div slot="content">Desire Lines</div>
          </Button>
          <Button
            className="plain"
            disabled={Object.keys(parsedMaps).length === 0}
            style={"border-radius: 0 4px 4px 0; " +
              (showHeatMap ? activeButtonStyle : "outline: 1px solid #f4f4f4;")}
            on:click={() => toggleShowHeatMap(modes[0])}
          >
            <div slot="content">Heat Maps</div>
          </Button>
        </div>
      {/if}
    </div>
    <div class="tools">
      {#if shouldShowAnalysis}
        <span class="title">Select Modes:</span>
        <div class="swatches">
          {#each modes as mode}
            <!-- /*$heatMapData.fetching || $heatMapData.stale*/ -->
            <Button
              disabled={Object.keys(parsedMaps).length === 0}
              on:click={() => toggleModes(mode)}
              className={"small spaced plain swatch " +
                mode +
                (hiddenModes.includes(mode) ||
                (showHeatMap && showHeatMap !== mode)
                  ? " hidden"
                  : "")}
            >
              <div slot="content">
                <img
                  src="assets/images/{mode}.svg"
                  height="25"
                  width="25"
                  alt={mode}
                />
              </div>
            </Button>
          {/each}
        </div>
        <div class="info-wrap">
          <!-- !$heatMapData.fetching -->
          {#if true}
            <Tooltip top="45px" right="-120px" {tooltip} />
          {/if}
        </div>
        <!-- $heatMapData.fetching || $heatMapData.stale -->
        <Button
          disabled={Object.keys(parsedMaps).length === 0}
          on:click={exportHeatmapImage}
        >
          <div slot="content">export image</div></Button
        >
      {/if}
    </div>
  </div>
  <div class="image-wrapper" id="heat-map">
    <div class="image-container" />
    <div bind:this={desireLineDiv} />
    <div bind:this={heatMapDiv} />
    <canvas
      bind:this={mask}
      class="canvas"
      style="z-index:2;"
      width="640"
      height="480"
    />
  </div>
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .wrapper {
    position: relative;
    vertical-align: top;
    background-size: 100%;
  }
  .image-wrapper {
    width: 640px;
    height: 480px;
    position: relative;
    background-size: 100%;
    display: flex;
    align-items: center;
  }
  .image-container {
    width: 640px;
    height: 480px;
    margin: 0;
    padding: 0;
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    z-index: 0;
  }
  .canvas {
    position: absolute;
    z-index: 1;
  }

  .tools-wrapper {
    height: 90px;
    .tools {
      display: flex;
      align-items: center;
      padding: 3px 0;
      .title {
        width: 128px;
      }
      .info-wrap {
        position: relative;
        display: flex;
        align-items: center;
        margin: 5px 10px 0 auto;
        align-self: center;
      }
      .swatches {
        display: flex;
      }
    }
  }
</style>
