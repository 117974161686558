<script lang="ts">
  import type { Organization } from "../../types/Organization";
  import type { DeviceNode } from "../../types/Device";
  import type { FeedCountMetricNode } from "../../types/FeedCountMetrics";
  import type { DateFilter, Interval, Mode } from "../../types/Filters";
  import { colors } from "../../constants/utils";
  import type { Metric } from "../../types/Graph";
  import { timeBucketedData } from "../../lib/utils";
  import { plotlyStore } from "../../stores/plotly";
  import { CONFIG, LINE_LAYOUT } from "../../constants/plotlyLayouts";
  import ChartTools from "./ChartTools.svelte";

  export let counts: Metric[];
  export let interval: Interval;
  export let sensor: DeviceNode;
  export let dateRange: DateFilter;
  export let organization: Organization;
  export let loading = true;
  let rendered = false;
  let plotDiv;
  let Plotly = $plotlyStore;
  let modeTraces = {};
  let modesToHide = [];
  const orderedModes = [
    "pedestrian",
    "bicycle",
    "motorbike",
    "bus",
    "car",
    "truck",
  ];

  function getDataByMode(transformedData: Metric[]) {
    const dataByClass = transformedData.reduce((acc, value: Metric) => {
      if (!acc[value.objClass]) {
        acc[value.objClass] = [];
      }
      acc[value.objClass].push(value);
      return acc;
    }, {});
    let modeTraces = {};
    for (const key in dataByClass) {
      let single_sensor = dataByClass[key];

      let timeBuckets = {};
      single_sensor.forEach((element: FeedCountMetricNode) => {
        timeBucketedData(timeBuckets, element, interval);
      });
      let x = Object.keys(timeBuckets);
      let y = Object.values(timeBuckets);
      const hovertemplate = `<b>%{x}</b><br>Count: %{y}<br>Mode: ${key}<extra></extra>`;
      const modeTrace = {
        hovertemplate,
        line: { color: colors[key], width: 3 },
        mode: "lines",
        name: key,
        x,
        y,
        type: "scatter",
        showlegend: true,
        legendrank: orderedModes.indexOf(key) + 1,
      };

      modeTraces[key] = modeTrace;
    }
    return modeTraces;
  }

  function updateGraph(traces) {
    // filter out hidden modes
    const selectedModes = Object.keys(traces).filter(
      (m) => !modesToHide.includes(m)
    );
    const plotData = selectedModes.map((m) => traces[m]);
    if (!rendered) {
      Plotly.newPlot(plotDiv, plotData, LINE_LAYOUT, CONFIG);
      rendered = true;
    } else {
      Plotly.react(plotDiv, plotData, LINE_LAYOUT, CONFIG);
    }
  }

  function useData(data: Metric[]) {
    // bucket data by mode and time for plotting
    modeTraces = getDataByMode(data);
    updateGraph(modeTraces);
  }

  $: {
    if (counts && !loading && plotDiv) {
      useData(counts);
    }
  }
</script>

<div class="plot-wrapper">
  <div class="chart-tools">
    <ChartTools
      {loading}
      plot={plotDiv}
      exportData={counts}
      fileName={`Numina-${organization.alias}-${sensor.alias}-${dateRange.startDate}-${dateRange.endDate}`}
      tooltip="Click and drag to zoom. Double click to reset. Use the legend to show and hide modes."
    />
  </div>
  <div class="plot" id="plotDiv" bind:this={plotDiv} />
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .plot-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 40px;
  }
  .chart-tools {
    position: absolute;
    right: -10px;
    top: 30px;
    height: max-content;
    width: 300px;
    z-index: 2;
  }
</style>
