<script lang="ts">
  export let tooltip: string;
  export let top = "0";
  export let right = "0";
</script>
<div class="info">
  <img
    class="icon"
    src="assets/images/info.svg"
    width="20"
    height="20"
    alt="info"
  />
  <span class="tooltip" style={`top:${top};right:${right}`}>{tooltip}</span>
</div>
<style scoped lang="scss">
  @use"theme.scss";
  .info {
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      margin-left: 5px;
      margin-top: 3px;

      .tooltip {
        position: absolute;
        visibility: hidden;
        width: 250px;
        font-size: 14px;
        font-family: Inter;
        text-align: center;
        padding: 5px 0;
        border-radius: 6px;
        border: 1px solid theme.$main;
        position: absolute;
        z-index: 10;
        background-color: white;
      }

      .icon:hover ~ .tooltip {
        visibility: visible;
      }
    }
</style>