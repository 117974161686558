<script lang="ts">
  import { createEventDispatcher } from "svelte";
  const dispatch = createEventDispatcher();
  export let title;
  export let items;
  export let valueKey = "";
  export let nameKey = "";
  export let classKey = "";
  export let checkedVals = [];
  export let selectAll = false;
  export let disabled = false;
  export let max = 0;

  function checkAll(checked) {
    const checks = document.getElementsByName(`checkbox-${title}`);
    //@ts-ignore
    checks.forEach((c) => (c.checked = checked));
  }

  function uncheckAll() {
    //@ts-ignore
    document.getElementById(`all-check-${title}`).checked = false;
  }

  $: disabledMaxSelected = (value: any) => {
    if (!max || max === 0) {
      return false;
    }
    if (checkedVals?.includes(value)) {
      return false;
    }
    if (checkedVals.length < max) {
      return false;
    }
    return true;
  };
</script>

<fieldset class="checkset" name="zones" id="zones">
  <legend>{title}</legend>
  {#each items as i}
    <label
      class="check-label"
      on:mouseenter={() => dispatch("mouseenter", { value: i })}
      on:mouseleave={() => dispatch("mouseleave", { value: i })}
    >
      <input
        type="checkbox"
        name={`checkbox-${title}`}
        value={valueKey ? i[valueKey] : i}
        checked={checkedVals?.includes(valueKey ? i[valueKey] : i)}
        disabled={disabled || disabledMaxSelected(valueKey ? i[valueKey] : i)}
        on:change={() => {
          if (selectAll) {
            uncheckAll();
          }
          dispatch("change", { value: valueKey ? i[valueKey] : i });
        }}
      />
      <span
        class={"checkmark " +
          (classKey ? i[classKey] : "") +
          (disabled ? " disabled" : "")}
      />
      <span
        class={"label-text " +
          (disabled && checkedVals?.includes(valueKey ? i[valueKey] : i)
            ? "selected"
            : "")}>{nameKey ? i[nameKey] : i}</span
      >
    </label>
  {/each}
  {#if !disabled && selectAll}
    <label class="check-label">
      <input
        id={`all-check-${title}`}
        type="checkbox"
        name="all-checkbox"
        value={"ALL"}
        checked={items.length === checkedVals.length}
        on:change={(e) => {
          //@ts-ignore
          const checked = e.target.checked;
          checkAll(checked);
          dispatch("change", { value: "ALL", checked });
        }}
      />
      <span class="checkmark" />
      <span class="label-text">all</span>
    </label>
  {/if}
  <slot name="button" />
</fieldset>

<style scoped lang="scss">
  @use "theme.scss";
  .checkset {
    border-radius: 8px;
    border-style: solid;
    border-color: theme.$main;
    height: 275px;
    width: 200px;
    overflow-y: scroll;
  }

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.5);
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
  .check-label {
    display: block;
    position: relative;
    cursor: pointer;
    margin-bottom: 10px;

    input {
      appearance: none;
    }

    .label-text {
      max-width: 150px;
      display: inline-block;
      margin-left: 15px;

      &.selected {
        font-weight: bold;
      }
    }
    .checkmark {
      position: absolute;
      top: 3px;
      left: 0;

      background-color: #eee;
      border: 0.125em solid #ddd;
      border-radius: 0.25em;
      cursor: pointer;
      height: 1em;
      width: 1em;
      transition: transform 100ms ease 0.25s;
    }

    input:disabled ~ .checkmark,
    input:disabled ~ .label-text {
      opacity: 0.6;
      cursor: initial;
    }
    input:checked ~ .checkmark {
      background-color: theme.$gray;
      transition: transform 100ms ease 0.25s;

      &.pedestrian {
        background-color: theme.$ped;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
      &.bicycle {
        background-color: theme.$bike;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
      &.bus {
        background-color: theme.$bus;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
      &.motorbike {
        background-color: theme.$motorbike;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
      &.car {
        background-color: theme.$car;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
      &.truck {
        background-color: theme.$truck;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }

      &.dog {
        background-color: theme.$dog;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
      &.kickboard {
        background-color: theme.$kickboard;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
      }
      &.van {
        background-color: theme.$van;
        &::after {
          border: solid white;
          border-width: 0 2px 2px 0;
        }
        background-color: theme.$van;
      }
    }

    .checkmark:after {
      content: "";
      position: absolute;
      display: none;
    }

    /* Show the checkmark when checked */
    input:checked ~ .checkmark:after {
      display: block;
      transition: transform 100ms ease 0.25s;
    }

    /* Style the checkmark/indicator */
    .checkmark:after {
      left: 5px;
      top: 1px;
      width: 5px;
      height: 10px;
      border: solid theme.$black;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      transition: transform 100ms ease 0.25s;
    }
  }
</style>
