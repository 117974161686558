<script lang="ts">
  import { mutationStore, getContextClient } from "@urql/svelte";
  import axios from "axios";
  import Button from "../baseComponents/Button.svelte";
  import { storeUser } from "../../lib/storage";
  import { LOG_IN } from "../../queries/logIn";
  import { trackEvent } from "../../lib/analytics";
  import { getErrorMessage, validateEmail } from "../../lib/utils";
  import Switch from "../baseComponents/Switch.svelte";
  import { apiStore } from "../../stores/app";
  import { dataVersions } from "../../constants/utils";

  export let ssoError = "";
  let email: string = "";
  let password: string = "";
  $: loginError = ssoError || "";
  $: loading = false;
  $: useSSO = false;
  let client = getContextClient();
  const submitLogin = () => {
    return mutationStore({
      client,
      query: LOG_IN,
      variables: { email, password },
    });
  };

  function handleLogin(e) {
    e.preventDefault();
    if (!email || (!useSSO && !password)) {
      loginError = "Please enter an email and password";
      return;
    } else if (!validateEmail(email)) {
      loginError = "Please enter a valid email address";
      return;
    } else {
      loginError = "";
    }
    if (useSSO) {
      return ssoLogin();
    }
    submitLogin().subscribe(({ fetching, data, error }) => {
      if (fetching) {
        loading = true;
      } else if (error) {
        loading = false;
        loginError = getErrorMessage(error);
      }
      if (data?.logIn?.jwt?.token) {
        trackEvent("login");
        const token = data.logIn.jwt.token;
        storeUser(token, data.logIn.user, {
          dataVersion: dataVersions[1].value,
        });
      }
    });
  }

  async function ssoLogin() {
    const url = $apiStore + "/sso";
    const data = { email };
    loading = true;
    axios
      .post(url, data)
      .then((resp) => {
        loading = false;
        if (!resp.data?.data?.location) {
          return (loginError = "There was an error logging in");
        }
        const url = resp.data.data.location;
        window.location.href = url;
      })
      .catch((e) => {
        loading = false;
        loginError =
          e.response?.data?.message || "There was an error logging in";
      });
  }
</script>

<form class="login-form">
  <div class="row spaced">
    <p>
      Numina is a deploy-anywhere sensor solution that gives cities
      unprecedented traffic data.
    </p>
  </div>
  <div class="row">
    <input
      bind:value={email}
      id="email"
      type="email"
      placeholder="Email"
      autocomplete="email"
    />
  </div>
  {#if !useSSO}
    <div class="row">
      <input
        bind:value={password}
        id="password"
        type="password"
        placeholder="Password"
        autocomplete="current-password"
      />
    </div>
  {/if}
  <div class="row spaced">
    <Switch title="Use SSO" on:change={() => (useSSO = !useSSO)} />
    <Button
      className="spaced"
      type="submit"
      {loading}
      on:click={(e) => handleLogin(e)}
    >
      <div slot="content" id="login">Login</div>
    </Button>
  </div>
  <div />
</form>
{#if loginError.length}
  <div class="error" id="error">{loginError}</div>
{/if}

<style scoped lang="scss">
  @use "theme.scss";

  .login-form {
    height: 300px;
    width: 400px;
  }

  .row {
    width: 350px;
    display: flex;
    margin: 2px auto;
    justify-content: center;
    text-align: center;

    input {
      width: 200px;
    }

    p {
      font-size: 1em;
      line-height: 1.5;
    }
  }

  .error {
    border-radius: 8px;
    display: flex;
    justify-content: center;
    width: 70%;
    min-height: 40px;
    max-width: 250px;
    margin: auto;
    align-items: center;
    border: 1px solid theme.$error;
    background-color: theme.$error-background;
    color: theme.$error;
    text-align: center;
  }

  .spaced {
    margin: 20px auto;
  }
</style>
