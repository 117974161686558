<script lang="ts">
  import { colors } from "../../constants/utils";
  import { createEventDispatcher } from "svelte";
  import { Circle } from "svelte-loading-spinners";
  export let disabled = false;
  export let loading = false;
  export let className = "primary";
  export let style = "";
  export let type: "button" | "submit" | "reset" = "button";
  export let tooltip = "";
  const dispatch = createEventDispatcher();
</script>

<div class="wrapper" class:disabled={disabled || loading}>
  <button
    class={"btn " + className}
    disabled={disabled || loading}
    {style}
    {type}
    on:click
    on:mouseenter={() => dispatch("hover")}
  >
    {#if loading}
      <div class={"loader " + className}>
        <Circle size="10" color={colors.pedestrian} unit="px" duration="2s" />
      </div>
    {:else}
      <slot name="content" />
    {/if}
  </button>
  {#if tooltip}
    <span class="tooltip">{tooltip}</span>
  {/if}
</div>

<style scoped lang="scss">
  @use "theme.scss";

  .wrapper {
    position: relative;
    display: inline-block;

    &.disabled {
      pointer-events: none;
    }

    .tooltip {
      position: absolute;
      visibility: hidden;
      width: max-content;
      font-size: 14px;
      font-family: Inter;
      text-align: center;
      padding: 5px;
      border-radius: 6px;
      border: 1px solid theme.$main;
      position: absolute;
      z-index: 1;
      top: -35px;
      left: -50%;
      background-color: white;
      color: theme.$black;
    }
    &:hover {
      .tooltip {
        visibility: visible;
        filter: brightness(100%);
      }
    }
  }
  .btn {
    border-radius: 8px;
    border: 1px solid transparent;
    font-size: 1em;
    font-weight: 500;
    cursor: pointer;
    transition: border-color 0.25s;
    min-width: 100px;
    padding: 6px;
    font-family: Inter;
    background-color: theme.$main;
    color: white;

    &:hover {
      outline: solid white 1px;
      box-shadow: 0 0 5px theme.$main;
      transition: box-shadow 0.3s ease-in-out;
    }

    &:disabled {
      pointer-events: none;
      background-color: theme.$gray;
      color: theme.$gray-out;
    }
    &.small {
      min-width: 30px;
      max-height: 33px;
      padding: 3px;
    }

    &.hidden {
      filter: opacity(20%);
    }

    &.plain {
      background-color: theme.$gray;
      color: theme.$black;

      &:hover {
        box-shadow: none;
      }
    }

    &.active {
      outline: 1px solid theme.$main;
    }

    &.spaced {
      margin: 0 5px;
    }

    &.outlined {
      border: 1px solid theme.$black;
      &:hover {
        border: 1px solid theme.$main;
      }
    }

    &.link {
      background-color: unset;
      color: theme.$main;
      border: none;
      &:hover {
        text-decoration: underline;
        border: none;
        outline: none;
        box-shadow: none;
      }
    }

    &.icon {
      background-color: unset;
      color: theme.$main;
      border: none;
      padding: 3px;
      min-width: 30px;
      max-height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        outline: 1px solid theme.$black;
        box-shadow: none;
      }
    }

    .loader {
      padding: 6px;
      display: flex;
      justify-content: center;
      pointer-events: none;
    }

    .icon {
      margin: 0 2px;
      vertical-align: middle;
    }
    &.swatch {
      &.pedestrian {
        background-color: theme.$main;
      }
      &.bicycle {
        background-color: theme.$bike;
      }
      &.motorbike {
        background-color: theme.$motorbike;
      }
      &.bus {
        background-color: theme.$bus;
      }
      &.car {
        background-color: theme.$car;
      }
      &.truck {
        background-color: theme.$truck;
      }
      &.dog {
        background-color: theme.$dog;
      }
      &.kickboard {
        background-color: theme.$kickboard;
      }
      &.van {
        background-color: theme.$van;
      }
      &.hidden {
        filter: opacity(20%);
      }
    }

    .none {
      display: none;
    }
  }
</style>
