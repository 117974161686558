import { writable, type Writable } from "svelte/store";
import type { User, Settings } from "../types/User";
import { dataVersions } from "../constants/utils";

export const userStore: Writable<User | null> = writable(null);
export const tokenStore: Writable<string | null> = writable("");
export const settingsStore: Writable<Settings> = writable({
  dataVersion: dataVersions[1].value,
});

export const resetUserStore = () => {
  userStore.set(null);
  tokenStore.set("");
  settingsStore.set({ dataVersion: dataVersions[1].value });
};
