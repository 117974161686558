import { gql } from "@urql/svelte";

export const SSO_LOG_IN = gql`
  mutation ($token: String!) {
    ssoLogIn(token: $token) {
      jwt {
        token
        exp
      }
      user {
        email
        organization {
          name
          alias
          rawId
          orgLat
          orgLon
          timezone
          zoomLevel
        }
      }
    }
  }
`;
