const hoverlabel = {
  font: { color: "#333", size: 14, family: "Inter" },
  bgcolor: "#f4f4f4",
  bordercolor: "#f4f4f4",
};

const font = { family: "Inter", size: 12 };

export const LINE_LAYOUT = {
  margin: { r: 5, t: 75, l: 5, b: 5 },
  autosize: false,
  width: 800,
  height: 400,
  plot_bgcolor: "white",
  paper_bgcolor: "white",
  font,
  hoverlabel,
  line: { shape: "spline" },
  type: "scatter",
  yaxis: {
    //zerolinecolor: "white",
    automargin: true,
    ticks: "outside",
    tickwidth: 0.75,
    tickcolor: "#272727",
    ticklen: 7,
    zeroline: false,
    linewidth: 1.25,
    linecolor: "#272727",
    showgrid: true,
    griddash: "dash",
    gridwidth: 0.25,
    gridcolor: "#dcdcdc",
    rangemode: "tozero",
  },
  xaxis: {
    showgrid: false,
    automargin: true,
    ticks: "outside",
    tickwidth: 0.75,
    tickcolor: "#272727",
    ticklen: 7,
    zeroline: true,
    nticks: 10,
    linewidth: 1.25,
    linecolor: "#272727",
    tickformat: "%b %d, %y",
  },
  title: {
    text: "Volumes by Mode",
    font: { ...font, size: 22 },
    x: 0,
    y: 0.99,
    xanchor: "left",
    yanchor: "top",
  },
  showlegend: true,
  legend: {
    font: {
      size: 14,
    },
    y: 15,
    orientation: "h",
  },
};

export const HEATMAP_LAYOUT = {
  xaxis: {
    automargin: true,
    ticks: "",
    zeroline: false,
    range: [0, 640],
    autorange: false,
    showticklabels: false,
    showgrid: false,
  },
  yaxis: {
    automargin: true,
    ticks: "",
    zeroline: false,
    autorange: false,
    range: [480, 0],
    showticklabels: false,
    showgrid: false,
  },
  margin: { t: 0, l: 0, r: 0, b: 0 },
  font,
  paper_bgcolor: "rgba(0,0,0,0)",
  plot_bgcolor: "rgba(0,0,0,0)",
  width: 640,
  height: 480,
  hoverlabel,
};

export const PIE_LAYOUT = {
  plot_bgcolor: "white",
  paper_bgcolor: "white",
  font: { family: "Inter", size: 9 },
  hoverlabel,
  showlegend: true,
  width: 350,
  height: 350,
  margin: { l: 5, r: 5, b: 5, t: 45 },
  uniformtext: { minsize: 12, mode: "hide" },
  title: {
    text: "Mode Share",
    font: { ...font, size: 22 },
    x: 0,
    y: 0.99,
    xanchor: "left",
    yanchor: "top",
  },
  legend: {
    font: {
      size: 14,
    },
  },
};

export const MODE_SHARE_LAYOUT = {
  template: {
    data: {
      bar: [
        {
          marker: {
            line: { color: "#E5ECF6", width: 0.5 },
          },
          type: "bar",
        },
      ],
    },
    layout: {
      font,
      xaxis: {
        gridcolor: "white",
        linecolor: "white",
        ticks: "",
        title: { standoff: 15 },
        zerolinecolor: "white",
        automargin: true,
        zerolinewidth: 2,
      },
      yaxis: {
        gridcolor: "white",
        linecolor: "white",
        ticks: "",
        title: { standoff: 15 },
        zerolinecolor: "white",
        automargin: true,
        zerolinewidth: 2,
      },
      scene: {
        xaxis: {
          backgroundcolor: "#E5ECF6",
          gridcolor: "white",
          linecolor: "white",
          showbackground: true,
          ticks: "",
          zerolinecolor: "white",
          gridwidth: 2,
        },
        yaxis: {
          backgroundcolor: "#E5ECF6",
          gridcolor: "white",
          linecolor: "white",
          showbackground: true,
          ticks: "",
          zerolinecolor: "white",
          gridwidth: 2,
        },
        zaxis: {
          backgroundcolor: "#E5ECF6",
          gridcolor: "white",
          linecolor: "white",
          showbackground: true,
          ticks: "",
          zerolinecolor: "white",
          gridwidth: 2,
        },
      },
      shapedefaults: { line: { color: "#2a3f5f" } },
      annotationdefaults: {
        arrowcolor: "#2a3f5f",
        arrowhead: 0,
        arrowwidth: 1,
      },
    },
  },
  xaxis: {
    anchor: "y",
    domain: [0.0, 1.0],
    title: {},
    tickformat: ".r",
    showticklabels: false,
    showgrid: true,
  },
  yaxis: { anchor: "x", domain: [0.0, 1.0], title: {}, ticksuffix: "   " },
  legend: { title: { text: "objClass" }, tracegroupgap: 0 },
  margin: { t: 30, l: 0, r: 0, b: 0 },
  barmode: "relative",
  height: 300,
  width: 640,
  showlegend: false,
  paper_bgcolor: "white",
  plot_bgcolor: "white",
  bargap: 0.25,
  hoverlabel,
  hovermode: "y unified",

  title: {
    text: "Mode Share for Vulnerable Road Users, Last 30 Days",
    font: { ...font, size: 22 },
    x: 0,
    y: 0.99,
    xanchor: "left",
    yanchor: "top",
  },
};

export const CONFIG = {
  displayModeBar: false,
  responsive: true,
};
