import Cookies from "js-cookie";
import { resetDeviceStore } from "../stores/devices";
import type { Settings, User } from "../types/User";
import { resetFilterStores } from "../stores/filters";
import {
  resetUserStore,
  settingsStore,
  tokenStore,
  userStore,
} from "../stores/user";
import { apiStore, statusApiStore } from "../stores/app";

export const getStorage = (
  key: string,
  defaultValue: string | Record<string, unknown>
): string | Record<string, unknown> => {
  if (typeof window !== "undefined") {
    return Cookies.get(key) ? JSON.parse(Cookies.get(key)) : defaultValue;
  }

  return defaultValue;
};

export const setStorage = (
  key: string,
  data: string | Record<string, unknown>
) => {
  if (typeof window !== "undefined") {
    return Cookies.set(key, JSON.stringify(data), { expires: 1 });
  }

  return null;
};

export const removeStorage = (key) => {
  if (typeof window !== "undefined") {
    return Cookies.remove(key);
  }

  return null;
};

export const initLocalStorage = () => {
  // on loading fetch any active user + token from local storage
  const user = getStorage("user", null) as User;
  const token = getStorage("token", "") as string;
  const settings = getStorage("settings", null) as Settings;
  if (user && token) {
    userStore.set(user);
    tokenStore.set(token);
    settingsStore.set(settings);
  }
};

export const initAPIStorage = () => {
  // initialize API URL based on build environment
  let url;
  let statusUrl = "https://status.numina.co";
  if (import.meta.env.VITE_BUILD_ENV === "test") {
    url = "https://api-test.numina.dev";
    statusUrl = "https://security-testing.status.numina.co";
  } else if (import.meta.env.VITE_BUILD_ENV === "dev") {
    url = "https://api-dev.numina.dev";
  } else {
    url = import.meta.env.VITE_API_URL || "";
  }
  apiStore.set(url);
  statusApiStore.set(statusUrl);
  return url;
};

export const clearAll = () => {
  removeStorage("token");
  removeStorage("user");
  resetUserStore();
  resetFilterStores();
  resetDeviceStore();
};

export const storeUser = (token: string, user: User, settings: Settings) => {
  // save the user and token in the svelte store as well as local storage
  // to persist on refreshing the site
  setStorage("token", token);
  setStorage("user", user);
  setStorage("settings", settings);
  userStore.set(user);
  tokenStore.set(token);
  settingsStore.set(settings);
};

export default { getStorage, setStorage };
