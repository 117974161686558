import { gql } from "@urql/svelte";

export const RESET_PASSWORD = gql`
  mutation resetPassword(
    $token: String!
    $newPassword: String!
    $confirmPassword: String!
  ) {
    resetPassword(
      token: $token
      newPassword: $newPassword
      confirmPassword: $confirmPassword
    ) {
      email
    }
  }
`;
