<script lang="ts">
  import { useLocation, useNavigate } from "svelte-navigator";
  import PrivateRouteGuard from "../components/PrivateRouteGuard.svelte";
  import { getContextClient, mutationStore } from "@urql/svelte";
  import { SSO_LOG_IN } from "../queries/ssoLogin";
  import { trackEvent } from "../lib/analytics";
  import { storeUser } from "../lib/storage";
  import Loading from "../components/baseComponents/Loading.svelte";
  import { dataVersions } from "../constants/utils";

  const location = useLocation();
  const navigate = useNavigate();

  const params = new URLSearchParams($location.search);
  const token = params.get("token");
  if (!token) {
    // no SSO token found- redirect to login
    navigate("/login");
  } else {
    // SSO token found- log in user
    let client = getContextClient();
    mutationStore({
      client,
      query: SSO_LOG_IN,
      variables: { token },
    }).subscribe(({ data, error }) => {
      if (error) {
        // SSO authentication failed, redirect to login page
        navigate("/login", { replace: true, state: { error } });
      } else if (data) {
        // SSO authentication successful. Log in user
        trackEvent("sso-login");
        const token = data.ssoLogIn.jwt.token;
        storeUser(token, data.ssoLogIn.user, {
          dataVersion: dataVersions[1].value,
        });
      }
    });
  }
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-missing-attribute -->
<PrivateRouteGuard authRequired={false}>
  <slot>
    <div class="sso-login">
      <Loading />
    </div>
  </slot>
</PrivateRouteGuard>

<style scoped lang="scss">
  @use "theme.scss";
</style>
