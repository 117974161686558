import { gql } from "@urql/svelte";

export const UPDATE_BEHAVIOR_ZONE = gql`
  mutation updateBehaviorZone(
    $text: String
    $color: String
    $zoneId: Int!
    $demarcation: [[Int]]
  ) {
    updateBehaviorZone(
      text: $text
      color: $color
      zoneId: $zoneId
      demarcation: $demarcation
    ) {
      behaviorZone {
        id
        text
        color
        rawId
        feedId
        zoneType
        demarcation
      }
    }
  }
`;
