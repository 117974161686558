<script lang="ts">
  // @ts-nocheck
  import { createEventDispatcher, onDestroy } from "svelte";
  import Button from "./baseComponents/Button.svelte";

  const dispatch = createEventDispatcher();
  const close = () => dispatch("close");

  let modal;
  export let className = "";

  const handle_keydown = (e) => {
    if (e.key === "Escape") {
      close();
      return;
    }

    if (e.key === "Tab") {
      // trap focus
      const nodes = modal.querySelectorAll("*");
      const tabbable = Array.from(nodes).filter((n) => n.tabIndex >= 0);

      let index = tabbable.indexOf(document.activeElement);
      if (index === -1 && e.shiftKey) index = 0;

      index += tabbable.length + (e.shiftKey ? -1 : 1);
      index %= tabbable.length;

      tabbable[index].focus();
      e.preventDefault();
    }
  };

  const previously_focused =
    typeof document !== "undefined" && document.activeElement;

  if (previously_focused) {
    onDestroy(() => {
      previously_focused.focus();
    });
  }
</script>

<svelte:window on:keydown={handle_keydown} />

<!-- svelte-ignore a11y-click-events-have-key-events -->
<div class="modal-background" on:click={close} />

<div
  class={"modal " + className}
  role="dialog"
  aria-modal="true"
  bind:this={modal}
>
  <div class="header">
    {#if className !== "extra-small"}
      <slot name="header" />
      <div class="close">
        <Button className="small" autofocus on:click={close}>
          <div style="display:flex;justify-content: center;" slot="content">
            <img
              style="justify-self: center;"
              src="assets/images/close.svg"
              width="20"
              alt="close"
            />
          </div>
        </Button>
      </div>
    {/if}
  </div>
  <slot name="body" />
  <!-- svelte-ignore a11y-autofocus -->
</div>

<style scoped lang="scss">
  .modal-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    z-index: 98;
  }

  .modal {
    position: fixed;
    left: 50%;
    top: 53%;
    width: max-content;
    height: max-content;
    overflow: auto;
    transform: translate(-50%, -50%);
    padding: 1em;
    border-radius: 0.2em;
    background: white;
    z-index: 99;
    display: flex;
    flex-direction: column;

    &.small {
      width: 50%;
      height: 50%;
    }

    &.extra-small {
      width: 30%;
      height: max-content;
    }
  }

  .header {
    display: flex;
    max-height: 50px;
  }
  .close {
    margin-left: auto;
  }
</style>
