<script lang="ts">
  import { mutationStore, getContextClient } from "@urql/svelte";
  import Button from "../baseComponents/Button.svelte";
  import { RESET_PASSWORD } from "../../queries/resetPassword";
  import { getErrorMessage } from "../../lib/utils";
  import { useLocation, useNavigate } from "svelte-navigator";
  import { trackEvent } from "../../lib/analytics";

  export let token;
  let newPassword: string = "";
  let confirmPassword: string = "";
  $: err = "";
  $: loading = false;
  $: done = false;
  const client = getContextClient();
  const TOKEN_ERROR = "Token Not Found";
  const navigate = useNavigate();
  const location = useLocation();

  const submitPasswordReset = () => {
    return mutationStore({
      client,
      query: RESET_PASSWORD,
      variables: { token, newPassword, confirmPassword },
    });
  };

  function handleReset() {
    if (!newPassword || !confirmPassword) {
      err = "Please enter a new password";
      return;
    } else if (newPassword != confirmPassword) {
      err = "Please ensure passwords match";
      return;
    } else {
      err = "";
    }
    trackEvent("reset-password");
    submitPasswordReset().subscribe(({ fetching, data, error }) => {
      if (fetching) {
        loading = true;
      } else if (error) {
        loading = false;
        err = getErrorMessage(error);
        if (err === TOKEN_ERROR) {
          // expired or incorrect token- redirect to start of the reset flow
          setTimeout(() => {
            navigate("/login/reset", {
              state: { from: $location.pathname },
              replace: true,
            });
          }, 2000);
        }
      } else if (data) {
        loading = false;
        done = true;
        setTimeout(() => {
          navigate("/login", {
            state: { from: $location.pathname },
            replace: true,
          });
        }, 1000);
      }
    });
  }
</script>

<form class="login-form">
  <div class="row spaced">
    <h2>Create a new password</h2>
  </div>
  <div class="row">
    <input
      bind:value={newPassword}
      id="newPassword"
      type="password"
      placeholder="New Password"
      autocomplete="new-password"
    />
  </div>
  <div class="row">
    <input
      bind:value={confirmPassword}
      id="confirmPassword"
      type="password"
      placeholder="Retype Password"
      autocomplete="new-password"
    />
  </div>
  <div class="row spaced">
    <Button {loading} on:click={handleReset}
      ><div slot="content">Submit</div></Button
    >
  </div>
  {#if err}
    <div class="row alert error">{err}</div>
  {:else if done}
    <div class="row alert success">Done!</div>
  {/if}
  <div class="row">
    <a href="/login">Log in</a>
  </div>
  <div />
</form>

<style scoped lang="scss">
  @use "theme.scss";

  .login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    border-radius: 8px;
    padding: 20px;
  }

  h2 {
    font-size: 1.75rem;
    font-family: Inter;
    font-weight: bold;
  }

  .row {
    width: 350px;
    display: flex;
    margin: 2px auto;
    justify-content: center;
    text-align: center;

    input {
      width: 200px;
    }

    a {
      color: theme.$main;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .spaced {
    margin: 20px auto;
  }

  .alert {
    border-radius: 8px;
    display: flex;
    text-align: center;
    width: 70%;
    min-height: 40px;
    margin: 20px auto;
    align-items: center;

    &.error {
      border: 1px solid theme.$error;
      background-color: theme.$error-background;
      color: theme.$error;
    }

    &.success {
      border: 1px solid theme.$main;
      background-color: theme.$success-background;
      color: theme.$main;
    }
  }
</style>
