import { gql } from "@urql/svelte";
import type { ZoneHeatMaps } from "src/types/ZoneHeatMaps";

export const GET_ZONE_HEAT_MAP = gql`
  query ZoneHeatmaps(
    $zoneIds: [Int]!
    $objClasses: [String]!
    $startTime: DateTime!
    $endTime: DateTime!
    $timezone: String
    $samplePercent: Float
    $dataVersion: DataVersionEnum
  ) {
    zoneHeatmaps(
      zoneIds: $zoneIds
      objClasses: $objClasses
      startTime: $startTime
      endTime: $endTime
      timezone: $timezone
      samplePercent: $samplePercent
      dataVersion: $dataVersion
    ) {
      edges {
        node {
          objClass
          heatmap
          time
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const zoneHeatmapTransformer = (data: ZoneHeatMaps) => {
  return data?.zoneHeatmaps?.edges?.map((e) => e.node) || [];
};
