<script lang="ts">
  import PrivateRouteGuard from "../components/PrivateRouteGuard.svelte";
  import LoginForm from "../components/login/LoginForm.svelte";
  import StartPasswordReset from "../components/login/StartPasswordReset.svelte";
  import { useParams, useLocation } from "svelte-navigator";
  import { getErrorMessage } from "../lib/utils";
  import { onDestroy, onMount } from "svelte";

  const params = useParams();
  const location = useLocation();
  let loggingIn = $params?.reset ? false : true;
  $: error = $location.state?.error;

  onMount(() => {
    // remove the error from the location state so that it doesn't show up again
    window.history.replaceState({}, document.title, "/");
  });
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-missing-attribute -->
<PrivateRouteGuard authRequired={false}>
  <slot>
    <div class="login">
      <h2>
        {loggingIn
          ? "Real-time insights from streets"
          : "Forgot your password?"}
      </h2>
      {#if loggingIn}
        <LoginForm ssoError={error ? getErrorMessage(error) : ""} />
      {:else}
        <StartPasswordReset />
      {/if}
      <div class="row">
        <a
          on:click={() => {
            loggingIn = !loggingIn;
          }}>{loggingIn ? "Forgot your password?" : "Log in"}</a
        >
      </div>
    </div>
  </slot>
</PrivateRouteGuard>

<style scoped lang="scss">
  @use "theme.scss";
  .login {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: theme.$gray;
    border-radius: 8px;
    padding: 20px;

    h2 {
      font-size: 1.75rem;
      font-family: Inter;
      font-weight: bold;
    }

    a {
      color: theme.$main;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }
  }
</style>
