<script lang="ts">
  import { formatLocation } from "../lib/utils";
  import type { DeviceNode } from "../types/Device";
  import { toasts, ToastContainer, FlatToast }  from "svelte-toasts";
  export let sensors: DeviceNode[];

  const showToast = (title: string) => {
    toasts.add({
      description: `${title} copied`,
      duration: 2000, // 0 or negative to avoid auto-remove
      placement: 'bottom-right',
      type: 'success',
      theme: 'dark',
    });
  };

  const clickToCopy = async (text: string, title: string) => {
    try {
        await navigator.clipboard.writeText(text);
      } catch {
        // could not copy text
      }
      showToast(title);
  }

</script>

<div class="list">
  <table>
    <tr>
      <th>Name</th>
      <th>Serial Number</th>
      <th>Latitude, Longitude</th>
    </tr>
    {#each sensors as sensor}
      <tr>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <td on:click={() => clickToCopy(sensor.name, "Name")}>{sensor.name}</td>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <td on:click={() => clickToCopy(sensor.serialno, "Serial Number")}>{sensor.serialno}</td>
        <!-- svelte-ignore a11y-click-events-have-key-events -->
        <td on:click={() => clickToCopy(formatLocation(sensor), "Location")}>{formatLocation(sensor)}</td>
      </tr>
    {/each}
  </table>
</div>
<ToastContainer placement="bottom-right" let:data={data}>
  <FlatToast {data} /> <!-- Provider template for your toasts -->
</ToastContainer>


<style scoped lang="scss">
  @use "theme.scss";
  .list {
    overflow: scroll;
    max-height: 70vh;

    table {
      border-spacing: 2px;
      tr {
        cursor: pointer;
        height: 40px;
        text-align: center;
        max-width: 150px;
  
        &:nth-child(odd){
          background-color: theme.$light-background;
        }

        td:hover {
          text-decoration: underline;
        }
      }
      th{
        cursor: default;
      }
    }
  }
</style>
