<script lang="ts">
  import type { Organization } from "../../types/Organization";
  import type { DeviceNode } from "../../types/Device";
  import type { DateFilter, Mode } from "../../types/Filters";
  import { colors } from "../../constants/utils";
  import type { Metric } from "../../types/Graph";
  import { plotlyStore } from "../../stores/plotly";
  import { CONFIG, PIE_LAYOUT } from "../../constants/plotlyLayouts";
  import ChartTools from "./ChartTools.svelte";
  import moment from "moment";

  export let counts: Metric[];
  export let sensor: DeviceNode;
  export let dateRange: DateFilter;
  export let organization: Organization;
  export let loading = true;
  let rendered = false;
  let plotDiv;
  let Plotly = $plotlyStore;
  let modeTraces = {};
  $: zeroCount = false;

  function getDataByMode(transformedData: Metric[]) {
    const dataByClass = transformedData.reduce((acc, value: Metric) => {
      if (!acc[value.objClass]) {
        acc[value.objClass] = [];
      }
      acc[value.objClass].push(value);
      return acc;
    }, {});
    for (const key in dataByClass) {
      const single_mode = dataByClass[key];
      let count = single_mode.reduce((acc, value: Metric) => {
        return acc + value.result;
      }, 0);

      modeTraces[key] = {
        count,
        color: colors[key],
      };
    }
    return modeTraces;
  }

  function updateGraph(traces) {
    let labels = [];
    let values = [];
    let colors = [];
    zeroCount = true;
    Object.keys(traces).forEach((m) => {
      if (traces[m].count > 0) {
        zeroCount = false;
      }
      labels.push(m);
      values.push(traces[m].count);
      colors.push(traces[m].color);
    });
    const formattedStartDate = moment(dateRange.startDate).format("MM/DD/YY");
    const formattedEndDate = moment(dateRange.endDate).format("MM/DD/YY");
    const hovertemplate = `${formattedStartDate} - ${formattedEndDate}<br><b>Mode:</b> %{label}<br><b>Count:</b> %{value}<br><b>Percentage:</b> %{percent}<br><extra></extra>`;
    const trace = [
      {
        hole: 0.65,
        insidetextorientation: "horizontal",
        labels,
        values,
        automargin: true,
        type: "pie",
        textposition: "inside",
        marker: {
          line: { color: "white", width: 2 },
          colors,
        },
        hovertemplate,
        textinfo: "percent",
        sort: false,
      },
    ];

    if (!zeroCount) {
      if (!rendered) {
        Plotly.newPlot(plotDiv, trace, PIE_LAYOUT, CONFIG);
        rendered = true;
      } else {
        Plotly.react(plotDiv, trace, PIE_LAYOUT, CONFIG);
      }
    }
  }

  function useData(data: Metric[]) {
    modeTraces = getDataByMode(data);
    updateGraph(modeTraces);
  }

  $: {
    if (counts && !loading && plotDiv) {
      useData(counts);
    }
  }
</script>

<div class="plot-wrapper">
  {#if !zeroCount}
    <div class="chart-tools">
      <ChartTools
        {loading}
        plot={plotDiv}
        fileName={`Numina-${organization.alias}-${sensor.alias}-${dateRange.startDate}-${dateRange.endDate}`}
      />
    </div>
  {:else}
    <div class="zero-data">No results for this filter</div>
  {/if}
  <div class="plot" id="plotDiv" bind:this={plotDiv} />
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .plot-wrapper {
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 40px;

    .chart-tools {
      position: absolute;
      right: -10px;
      top: 30px;
      height: max-content;
      width: 300px;
      z-index: 2;
    }

    .zero-data {
      margin: 50px auto;
    }
  }
</style>
