import html2canvas from "html2canvas";
import type { DeviceNode } from "../types/Device";
import type { Interval } from "../types/Filters";
import type { Metric } from "../types/Graph";
import { errorMessages } from "../constants/utils";

export const dateLabeller = (date: Date | string, interval: string) => {
  const formatMap = {
    "15m": {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "1h": {
      weekday: "short",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    },
    "1d": { weekday: "long", month: "short", day: "numeric", year: "numeric" },
    "1w": { weekday: "long", month: "short", day: "numeric", year: "numeric" },
    "1m": { weekday: "long", month: "short", day: "numeric", year: "numeric" },
    "1y": { weekday: "long", month: "short", day: "numeric", year: "numeric" },
  };
  let subStrDate = date.toString();
  if (date.toString().length > 19) {
    // slice off timezone so all dates are displayed as returned by the API
    subStrDate = date.toString().substring(0, date.toString().length - 6);
  }
  return new Date(subStrDate).toLocaleDateString("en-us", formatMap[interval]);
};

export function timeBucketedData(
  timeBuckets: Record<string, any>,
  metric: Metric,
  interval: Interval
) {
  const timestamp = dateLabeller(metric.time, interval);
  if (!timeBuckets[timestamp]) {
    timeBuckets[timestamp] = metric.result;
  } else {
    timeBuckets[timestamp] = timeBuckets[timestamp] + metric.result;
  }
}

export function getErrorMessage(
  error,
  message = "There was an error processing your request"
) {
  let cleanMessage;
  if (error?.graphQLErrors?.length > 0) {
    console.log("error?.graphQLErrors", error?.graphQLErrors);
    cleanMessage = error?.message.replace("[GraphQL]", "") || message;
  } else if (error?.networkError) {
    console.log("error?.networkError", error?.networkError);
    cleanMessage =
      error?.networkError?.message.replace("[Network] ") || message;
  }
  if (errorMessages[cleanMessage]) return errorMessages[cleanMessage];
  return cleanMessage || message;
}

export function validateEmail(email: string) {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
}

export function downloadFile(content: string, title: string) {
  const link = document.createElement("a");
  link.href = content;
  link.download = title;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export function scrollToAnchor(tag: string) {
  const anchor = document.getElementById(tag);
  window.scrollTo({
    top: anchor.offsetTop,
    behavior: "smooth",
  });
}

export const loadImage = (url): Promise<CanvasImageSource> => {
  return new Promise((fulfill, reject) => {
    const img = new Image();
    img.crossOrigin = "anonymous";
    img.onload = () => fulfill(img);
    img.src = url + "?v=" + Math.random();
  });
};

export function exportImage(
  div: HTMLElement,
  filename: string,
  bgImage?: CanvasImageSource,
  width = 640,
  height = 480,
  scale = 1
) {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  html2canvas(div, { scale, backgroundColor: "none" })
    .then(async (imgCanvas) => {
      const watermark = await loadImage(
        "assets/images/watermark-lightgray.png"
      );
      canvas.width = width;
      canvas.height = height;
      canvas.style.display = "none";
      ctx.globalCompositeOperation = "source-over";
      ctx.drawImage(watermark, 20, 420);

      ctx.globalCompositeOperation = "destination-over";
      ctx.drawImage(imgCanvas, 0, 0);
      if (bgImage) {
        ctx.drawImage(bgImage, 0, 0);
      }

      ctx.globalCompositeOperation = "source-over";
      const content = canvas.toDataURL("image/png");
      downloadFile(content, filename);
    })
    .catch((err) => {
      // TODO
    });
}

export const formatLocation = (sensor: DeviceNode, trunc = false) => {
  if (trunc) {
    return !!(sensor.location?.lat && sensor.location?.lon)
      ? `${sensor.location.lat.toFixed(2)}, ${sensor.location.lon.toFixed(2)}`
      : "";
  }
  return !!(sensor.location?.lat && sensor.location?.lon)
    ? `${sensor.location.lat}, ${sensor.location.lon}`
    : "";
};

export function groupBy(array: any[], key: string) {
  return array.reduce(function (result, item) {
    (result[item[key]] = result[item[key]] || []).push(item);
    return result;
  }, {});
}
