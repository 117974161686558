<script lang="ts">
  import { getContextClient, mutationStore } from "@urql/svelte";
  import Button from "../baseComponents/Button.svelte";
  import { getErrorMessage, validateEmail } from "../../lib/utils";
  import { START_RESET } from "../../queries/startReset";
  import { trackEvent } from "../../lib/analytics";

  let email;
  const client = getContextClient();
  let error = "";
  $: result = null;

  const startReset = (email) => {
    return mutationStore({
      client,
      query: START_RESET,
      variables: { email },
    });
  };
  function passwordReset() {
    if (!email || !validateEmail(email)) {
      error = "Please enter a valid email address";
    }
    result = startReset(email);
    trackEvent("start-reset-password");
  }
</script>

<form class="login-form">
  <div class="row spaced">
    <p>
      Enter your email address, and we'll send you a link to reset your
      password.
    </p>
  </div>
  <div class="row">
    <input
      bind:value={email}
      id="email"
      type="email"
      placeholder="Email"
      autocomplete="email"
    />
  </div>
  <div class="row spaced">
    <Button loading={$result?.fetching} on:click={passwordReset}
      ><div slot="content">Submit</div></Button
    >
  </div>
  {#if $result}
    {#if error}
      <div class="row alert error">
        {error}
      </div>
    {:else}
      <div class="row alert success">
        A password reset email will be sent if a matching account is found
      </div>
    {/if}
  {/if}
</form>

<style scoped lang="scss">
  @use "theme.scss";

  .login-form {
    height: 300px;
    width: 400px;
  }

  .row {
    width: 350px;
    display: flex;
    margin: 2px auto;
    justify-content: center;
    text-align: center;

    input {
      width: 200px;
    }

    p {
      font-size: 1em;
      line-height: 1.5;
    }
  }

  .spaced {
    margin: 20px auto;
  }
  .alert {
    border-radius: 8px;
    display: flex;
    text-align: center;
    width: 70%;
    min-height: 40px;
    margin: 20px auto;
    align-items: center;

    &.error {
      border: 1px solid theme.$error;
      background-color: theme.$error-background;
      color: theme.$error;
    }

    &.success {
      border: 1px solid theme.$main;
      background-color: theme.$success-background;
      color: theme.$main;
    }
  }
</style>
