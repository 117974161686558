import { gql } from "@urql/svelte";

export const LOG_IN = gql`
  mutation ($email: String!, $password: String!) {
    logIn(email: $email, password: $password) {
      jwt {
        token
        exp
      }
      user {
        email
        organization {
          name
          alias
          rawId
          orgLat
          orgLon
          timezone
          zoomLevel
        }
      }
    }
  }
`;
