<script lang="ts">
  import { getContextClient, queryStore } from "@urql/svelte";
  import {
    feedCountTransformer,
    GET_COUNT_METRICS,
  } from "../queries/getCountMetrics";
  import {
    GET_BEHAVIOR_ZONE_COUNT_METRICS,
    zoneCountTransformer,
  } from "../queries/getZoneCountMetrics";
  import {
    GET_SCREEN_LINE_COUNT_METRICS,
    screenLineCountTransformer,
  } from "../queries/getScreenLineCountMetrics";
  import {
    behaviorZoneFilterStore,
    dateFilterStore,
    intervalFilterStore,
    sensorFilterStore,
    modeFilterStore,
    invalidatedZoneIds,
    analysisTypeFilterStore,
    trendsFilterStore,
  } from "../stores/filters";
  import type { Organization } from "../types/Organization";
  import type { ZoneCountMetrics } from "../types/ZoneCountMetrics";
  import type { FeedCountMetrics } from "../types/FeedCountMetrics";
  import type { Metric } from "../types/Graph";
  import Loading from "./baseComponents/Loading.svelte";
  import { getErrorMessage } from "../lib/utils";
  import Button from "./baseComponents/Button.svelte";
  import { formatForViewing } from "../constants/dateRanges";
  import { AnalysisType, ZoneType } from "../types/Filters";
  import type { ScreenLineCountMetrics } from "src/types/ScreenLineCountMetrics";
  import Behaviors from "./Analysis/Behaviors.svelte";
  import Trends from "./Analysis/Trends.svelte";
  import Counts from "./Analysis/Counts.svelte";
  import { settingsStore } from "../stores/user";

  export let organization: Organization;
  export let zoneType: ZoneType;
  $: behaviorZones = $behaviorZoneFilterStore || [];
  $: dateRange = $dateFilterStore;
  $: sensor = $sensorFilterStore;
  $: interval = $intervalFilterStore;
  $: modes = $modeFilterStore;
  $: analysisType = $analysisTypeFilterStore;
  $: timeOfDay = $trendsFilterStore?.timeOfDay;
  $: dayOfWeek = $trendsFilterStore?.dayOfWeek;
  $: dataVersion = $settingsStore?.dataVersion;
  $: error = "";
  $: loadingData = true;
  $: zoneIds = behaviorZones.map((z) => z.rawId);
  $: baseVars = {
    startTime: dateRange.startDate,
    endTime: dateRange.endDate,
    objClasses: modes,
    timezone: organization.timezone,
    interval,
    dataVersion,
  };
  $: zoneVars = {
    ...baseVars,
    zoneIds,
  };
  $: feedVars = {
    ...baseVars,
    serialnos: [sensor?.serialno],
  };

  let shouldShowAnalysis;
  let counts: Metric[];

  function transformCountData(
    data: ZoneCountMetrics | FeedCountMetrics | ScreenLineCountMetrics
  ) {
    let transformedData: Metric[];
    if (behaviorZones.length) {
      if (zoneType === ZoneType.POLYGON) {
        transformedData = zoneCountTransformer(data as ZoneCountMetrics);
      } else {
        transformedData = screenLineCountTransformer(
          data as ScreenLineCountMetrics
        );
      }
    } else {
      transformedData = feedCountTransformer(data as FeedCountMetrics);
    }
    return transformedData;
  }

  $: {
    shouldShowAnalysis = !!(sensor && modes?.length && interval && dateRange);
    if (shouldShowAnalysis) {
      countData.resume();
    } else {
      countData.pause();
    }
  }

  const client = getContextClient();
  $: query = !behaviorZones?.length
    ? GET_COUNT_METRICS
    : zoneType === ZoneType.POLYGON
    ? GET_BEHAVIOR_ZONE_COUNT_METRICS
    : GET_SCREEN_LINE_COUNT_METRICS;
  $: variables = !behaviorZones?.length ? feedVars : zoneVars;

  $: countData = queryStore({
    client,
    pause: true,
    query,
    variables,
    requestPolicy: $invalidatedZoneIds.some((id) => zoneIds.includes(id))
      ? "network-only"
      : "cache-first",
  });

  $: {
    if ($countData.stale || $countData.fetching) {
      loadingData = true;
      error = "";
    } else if ($countData.error) {
      error = getErrorMessage($countData.error);
      loadingData = false;
    } else {
      counts = transformCountData($countData.data);
      loadingData = false;
    }
  }

  function retryCountsQuery() {
    error = "";
    loadingData = true;
    countData = queryStore({
      client,
      query,
      variables,
      requestPolicy: "network-only",
    });
  }

  const behaviorZoneText = () => {
    if (zoneType === ZoneType.POLYGON) {
      if (behaviorZones.length > 1) {
        return "in Behavior Zones";
      } else if (behaviorZones.length === 1) {
        return "in Behavior Zone";
      } else {
        return "";
      }
    } else {
      if (behaviorZones.length > 1) {
        return "crossing Screen Lines";
      } else if (behaviorZones.length === 1) {
        return "crossing Screen Line";
      } else {
        return "";
      }
    }
  };
</script>

<div>
  {#if shouldShowAnalysis}
    <div class="description">
      Viewing data for <span class="pedestrian bold">{sensor.name}</span> from
      <span class="bold">{formatForViewing(dateRange.startDate)}</span>
      to
      <span class="bold">{formatForViewing(dateRange.endDate)}</span>
      {behaviorZoneText()}
      <span class="bold">{behaviorZones.map((z) => z.text).join(", ")}</span>
      for modes
      {#each modes as mode}
        <span class={"bold " + mode}>{mode} </span>
      {/each}
    </div>
  {/if}
</div>
<div class="analysis-wrapper" id="analysis">
  {#if !shouldShowAnalysis}
    <div />
  {:else if loadingData}
    <div class="mask">
      <Loading />
    </div>
  {:else if error.length}
    <div class="mask error">
      <div class="error-content">
        <p>{getErrorMessage(error)}</p>
        <Button className="outlined" on:click={retryCountsQuery}
          ><div slot="content">Try Again</div></Button
        >
      </div>
    </div>
  {:else if analysisType === AnalysisType.COUNTS || analysisType === AnalysisType.TURNS}
    <Counts
      {counts}
      {sensor}
      {dateRange}
      {interval}
      {organization}
      loading={loadingData}
    />
  {:else if analysisType === AnalysisType.BEHAVIORS}
    <Behaviors
      {counts}
      {sensor}
      {dateRange}
      {interval}
      {organization}
      loading={loadingData}
    />
  {:else if analysisType === AnalysisType.TRENDS}
    <Trends
      {counts}
      {dateRange}
      {modes}
      loading={loadingData}
      {timeOfDay}
      {dayOfWeek}
    />
  {/if}
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .description {
    .bold {
      font-weight: bold;
    }

    .pedestrian {
      color: theme.$ped;
    }
    .bicycle {
      color: theme.$bike;
    }
    .motorbike {
      color: theme.$motorbike;
    }
    .bus {
      color: theme.$bus;
    }
    .truck {
      color: theme.$truck;
    }
    .car {
      color: theme.$car;
    }
    .kickboard {
      color: theme.$kickboard;
    }
    .van {
      color: theme.$van;
    }
    .dog {
      color: theme.$dog;
    }
  }

  .analysis-wrapper {
    display: flex;
    justify-content: space-between;
    position: relative;

    .mask {
      min-height: 600px;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 2;
    }
    .error-content {
      border-radius: 8px;
      padding: 30px;
      background-color: theme.$gray;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      align-self: flex-start;
      margin-top: 30px;
    }
  }

  @media only screen and (max-width: 1200px) {
    .analysis-wrapper {
      flex-direction: column;
    }
    .row {
      flex-direction: column;
    }
  }
</style>
