import moment from "moment";

export type DateFilter = {
  label: string;
  startDate: string;
  endDate: string;
};

export enum Mode {
  PEDESTRIAN = "pedestrian",
  BIKE = "bicycle",
  MOTORBIKE = "motorbike",
  BUS = "bus",
  CAR = "car",
  TRUCK = "truck",
  VAN = "van",
  KICKBOARD = "kickboard",
  // DOG = "dog",
}

export const allModesOldModel = [
  Mode.BIKE,
  Mode.BUS,
  Mode.CAR,
  Mode.MOTORBIKE,
  Mode.PEDESTRIAN,
  Mode.TRUCK,
];

export const allModes = [
  Mode.BIKE,
  Mode.BUS,
  Mode.CAR,
  Mode.MOTORBIKE,
  Mode.PEDESTRIAN,
  Mode.TRUCK,
  // Mode.DOG,
  Mode.KICKBOARD,
  Mode.VAN,
];

export enum Interval {
  FIFTEEN_MIN = "15m",
  HOUR = "1h",
  DAY = "1d",
  WEEK = "1w",
  MONTH = "1mo",
}

export type BaseVariables = {
  startTime: Date;
  endTime: Date;
  objClasses: [Mode];
  timezone: string;
  interval: Interval;
};

export enum ZoneType {
  POLYGON = "cpoly",
  SCREENLINE = "line",
}

export enum AnalysisType {
  COUNTS = "counts",
  BEHAVIORS = "behaviors",
  TRENDS = "trends",
  TURNS = "turns",
}

export type BehaviorZoneCountVariables = BaseVariables & { zoneIds: string[] };

export type SensorCountVariables = BaseVariables & { serialnos: string[] };

export type TrendFilter = {
  timeOfDay: TimeOfDay;
  dayOfWeek: string;
};

export const getIntervalsForDateRange = (
  label: string,
  startDate: Date | string,
  endDate: Date | string
): Interval[] => {
  if (label === "Today" || label === "Yesterday") {
    return [Interval.FIFTEEN_MIN, Interval.HOUR];
  } else if (label === "Past Week") {
    return [Interval.FIFTEEN_MIN, Interval.HOUR, Interval.DAY];
  } else if (label === "Past Month") {
    return [Interval.HOUR, Interval.DAY, Interval.WEEK];
  } else if (label === "Past 6 Months") {
    return [Interval.HOUR, Interval.DAY, Interval.WEEK, Interval.MONTH];
  } else {
    const diff = moment(endDate).diff(moment(startDate), "months");
    // custom range is a month or less
    if (diff <= 1) {
      return [Interval.FIFTEEN_MIN, Interval.HOUR, Interval.DAY, Interval.WEEK];
    }
    // custom range is more than a month
    return [Interval.DAY, Interval.WEEK, Interval.MONTH];
  }
};

export enum TimeOfDay {
  EARLY_MORNING = "EARLY_MORNING",
  MORNING_COMMUTE = "MORNING_COMMUTE",
  MORNING = "MORNING",
  NOON = "NOON",
  AFTERNOON = "AFTERNOON",
  EVENING_COMMUTE = "EVENING_COMMUTE",
  EVENING = "EVENING",
}

export const timeOfDayMap = {
  EARLY_MORNING: {
    name: "Early Morning",
    label: "(5a - 7a)",
  },
  MORNING_COMMUTE: {
    name: "Morning Commute",
    label: "(7am - 9am)",
  },
  MORNING: {
    name: "Morning",
    label: "(9am - 11am)",
  },
  NOON: {
    name: "Noon",
    label: "(11am - 2pm)",
  },
  AFTERNOON: {
    name: "Afternoon",
    label: "(2pm - 5pm)",
  },
  EVENING_COMMUTE: {
    name: "Evening Commute",
    label: "(5pm - 7pm)",
  },
  EVENING: {
    name: "Evening",
    label: "(7pm - 9pm)",
  },
};

export const dayOfWeekMap = {
  Weekdays: [1, 2, 3, 4, 5],
  Weekends: [6, 7],
  "All Days": [1, 2, 3, 4, 5, 6, 7],
};
