export const IMG_PATH =
  "https://numina-sensor-background-images.s3.amazonaws.com";

export const colors: Record<string, string> = {
  pedestrian: "#2FBA78",
  bicyclist: "#0FA7F0",
  bicycle: "#0FA7F0",
  car: "#FA684D",
  bus: "#863CF6",
  truck: "#D1232F",
  motorbike: "#FF66FF",
  dog: "#2D719F",
  kickboard: "#F7B32B",
  van: "#8C5516",
};

export const sampleImageUrl = (serialno: string) => {
  return serialno ? `${IMG_PATH}/${serialno}.png` : "";
};

export const errorMessages = {
  "Gateway Timeout":
    "We're still working on this query. Please try again and your results may be ready.",
  "Internal Server Error":
    "We're still working on this query. Please try again and your results may be ready.",
};

export const dataVersions = [
  {
    label: "July 2022",
    value: "JULY_2022",
  },
  {
    label: "June 2023",
    value: "JUNE_2023",
  },
];
