import { gql } from "@urql/svelte";
import type { FeedCountMetrics } from "../types/FeedCountMetrics";

export const GET_COUNT_METRICS = gql`
  query CountMetrics(
    $serialnos: [String]!
    $objClasses: [String]!
    $interval: String
    $timezone: String
    $startTime: DateTime!
    $endTime: DateTime!
    $dataVersion: DataVersionEnum
  ) {
    feedCountMetrics(
      serialnos: $serialnos
      objClasses: $objClasses
      interval: $interval
      timezone: $timezone
      startTime: $startTime
      endTime: $endTime
      dataVersion: $dataVersion
    ) {
      edges {
        node {
          feedid
          serialno
          objClass
          metric
          result
          time
        }
      }
    }
  }
`;

export const feedCountTransformer = (data: FeedCountMetrics) => {
  return data?.feedCountMetrics?.edges?.map((e) => e.node) || [];
};
