import { gql } from "@urql/svelte";

export const GET_BEHAVIOR_ZONE_LIST = gql`
  query BehaviorZones($serialnos: [String]!) {
    behaviorZones(serialnos: $serialnos) {
      edges {
        node {
          feedId
          zoneType
          demarcation
          feedId
          rawId
          color
          text
          id
        }
      }
    }
  }
`;
