import { gql } from "@urql/svelte";

export const CREATE_BEHAVIOR_ZONE = gql`
  mutation createBehaviorZone(
    $text: String
    $color: String
    $serialno: String!
    $demarcation: [[Int]]!
    $zoneType: String
  ) {
    createBehaviorZone(
      text: $text
      color: $color
      serialno: $serialno
      demarcation: $demarcation
      zoneType: $zoneType
    ) {
      behaviorZone {
        id
        text
        color
        rawId
        feedId
        zoneType
        demarcation
      }
    }
  }
`;
