<script lang="ts">
  import "leaflet/dist/leaflet.css";
  import L, { type LatLngExpression, type PopupOptions } from "leaflet";
  import { createEventDispatcher } from "svelte";
  import type { DeviceNode } from "../types/Device";
  import Loading from "./baseComponents/Loading.svelte";
  import { scrollToAnchor } from "../lib/utils";
  import { trackEvent } from "../lib/analytics";
  import { userStore } from "../stores/user";
  import Tooltip from "./baseComponents/Tooltip.svelte";
  import { deviceStore } from "../stores/devices";

  export let selectedSensor: DeviceNode;
  const dispatch = createEventDispatcher();
  const tooltip =
    "Hover to see sensor name and coordinates. Click to select the sensor.";
  $: sensors = $deviceStore;
  const organization = $userStore.organization;
  let markerLayers;

  // map implementation adapted from https://svelte.dev/repl/62271e8fda854e828f26d75625286bc3?version=3.52.0
  let map;
  function createMap(container) {
    let m = L.map(container, { preferCanvas: true });
    L.tileLayer("https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png", {
      attribution: `&copy;<a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>,
	        &copy;<a href="https://carto.com/attributions" target="_blank">CARTO</a>`,
      subdomains: "abcd",
      maxZoom: 25,
    }).addTo(m);

    return m;
  }

  function markerIcon(sensor: DeviceNode) {
    let html;
    if (sensor.inactive) {
      html = `<div class="map-marker"><img src='assets/images/circle-gray.svg' width='10' /></div>`;
    } else {
      html = `<div class="map-marker"><img src='assets/images/circle.svg' width='10' /></div>`;
    }
    return L.divIcon({
      html,
      className: "map-marker",
    });
  }

  function aziIcon(sensor: DeviceNode) {
    const azi = sensor.location.azi || 0;
    let html;
    if (sensor.inactive) {
      html = `<div class="map-marker"><img src='assets/images/circle-gray.svg' width='10' /></div>`;
    } else {
      html = `<div class="map-marker" style="transform: rotate(${azi}deg)"><img src='assets/images/azimuth.svg' width='50' /></div>`;
    }
    return L.divIcon({
      html,
      className: "map-marker",
    });
  }

  function resizeMap() {
    if (map) {
      map.invalidateSize();
    }
  }

  function selectSensor(sensor: DeviceNode) {
    scrollToAnchor("sensor-analysis");
    dispatch("select", { sensor });
    trackEvent("map-select-sensor");
  }

  function createMarker(sensor: DeviceNode, azi = false) {
    let icon;
    if (azi) {
      icon = aziIcon(sensor);
    } else {
      icon = markerIcon(sensor);
    }
    const loc: LatLngExpression = [sensor.location.lat, sensor.location.lon];
    const pane = sensor?.inactive ? "shadowPane" : "markerPane";
    let marker = L.marker(loc, { icon, pane });
    const ops: PopupOptions = {
      keepInView: true,
      closeButton: false,
      closeOnClick: false,
    };
    marker.bindPopup(
      `${sensor.name}<br/> Lat: ${sensor.location.lat} <br/>Long: ${sensor.location.lon}`,
      ops
    );
    marker.on("mouseover", function () {
      this.openPopup();
    });
    marker.on("mouseout", function () {
      this.closePopup();
    });
    marker.on("click", async () => {
      selectSensor(sensor);
    });
    return marker;
  }

  $: {
    // react to changes in the sensor list
    if (map) {
      markerLayers.clearLayers();
      const markers = [];
      for (let sensor of sensors) {
        if (sensor.location?.lat && sensor.location?.lon) {
          let m = createMarker(sensor);
          markers.push(m);
          markerLayers.addLayer(m);
        }
      }
      markerLayers.addTo(map);
      const group = L.featureGroup(markers);
      try {
        map.fitBounds(group.getBounds(), { maxZoom: 18 });
      } catch (e) {
        const bounds: LatLngExpression = [
          organization.orgLat || 0,
          organization.orgLon || 0,
        ];
        map.setView(bounds, 12);
      }
    }
  }

  $: {
    // react to changes in selected sensor
    if (selectedSensor) {
      // move map to selected sensor
      if (map) {
        const loc: LatLngExpression = [
          selectedSensor.location.lat,
          selectedSensor.location.lon,
        ];
        map.setView(loc, 18);
      }
    }
  }

  function mapAction(container) {
    markerLayers = L.layerGroup();
    map = createMap(container);
    map.on("zoomend", function () {
      if (map.getZoom() > 16) {
        markerLayers.clearLayers();
        const markers = [];
        for (let sensor of sensors) {
          if (sensor.location?.lat && sensor.location?.lon) {
            let m = createMarker(sensor, true);
            markers.push(m);
            markerLayers.addLayer(m);
          }
        }
        markerLayers.addTo(map);
      } else {
        markerLayers.clearLayers();
        const markers = [];
        for (let sensor of sensors) {
          if (sensor.location?.lat && sensor.location?.lon) {
            let m = createMarker(sensor);
            markers.push(m);
            markerLayers.addLayer(m);
          }
        }
        markerLayers.addTo(map);
      }
    });
    return {
      destroy: () => {
        map.remove();
        map = null;
      },
    };
  }
</script>

<svelte:window on:resize={resizeMap} />
<div class="header">
  <h2>Sensor Locations</h2>
  <Tooltip top="25px" right="-120px" {tooltip} />
</div>
{#if !sensors.length}
  <Loading />
{:else}
  <div class="wrapper">
    <div class="map" style="width:100%;height:100%;" use:mapAction />
  </div>
{/if}

<style lang="scss">
  @use "theme.scss";

  .header {
    display: flex;
    align-items: center;
  }
  .map {
    z-index: 0;
  }
  .map :global(.marker-text) {
    width: 350px;
    height: 570px;
    text-align: center;
    font-weight: 600;
    border-radius: 0.5rem;
  }

  .wrapper {
    width: 420px;
    height: 400px;
  }
</style>
