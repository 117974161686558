import { writable, type Writable } from "svelte/store";
import type { DeviceNode } from "../types/Device";
import type { DateFilter, Interval, Mode, TrendFilter } from "../types/Filters";
import type { BehaviorZoneNode } from "../types/BehaviorZone";
import { dateRanges } from "../constants/dateRanges";

export const sensorFilterStore: Writable<DeviceNode | null> = writable(null);
export const dateFilterStore: Writable<DateFilter> = writable(dateRanges[0]);
export const behaviorZoneFilterStore: Writable<BehaviorZoneNode[] | null> =
  writable(null);
export const intervalFilterStore: Writable<Interval | null> = writable(null);
export const modeFilterStore: Writable<Mode[] | []> = writable(null);
export const invalidatedZoneIds: Writable<string[]> = writable([]);
export const trendsFilterStore: Writable<TrendFilter | null> = writable();
export const analysisTypeFilterStore: Writable<string | null> = writable(null);

export const resetFilterStores = (sensor?: DeviceNode) => {
  sensorFilterStore.set(sensor || null);
  dateFilterStore.set(dateRanges[0]);
  behaviorZoneFilterStore.set(null);
  intervalFilterStore.set(null);
  trendsFilterStore.set(null);
};

export const addInvalidZone = (zoneId: string) => {
  invalidatedZoneIds.update((ids) => [...ids, zoneId]);
};
