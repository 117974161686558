<script lang="ts">
  import Papa from "papaparse";
  import Button from "../baseComponents/Button.svelte";
  import type { Metric } from "../../types/Graph";
  import { plotlyStore } from "../../stores/plotly";
  import { downloadFile, loadImage } from "../../lib/utils";
  import { trackEvent } from "../../lib/analytics";
  import Tooltip from "../baseComponents/Tooltip.svelte";

  export let loading = true;
  export let plot;
  export let exportData: Metric[] | undefined = undefined;
  export let fileName = "";
  export let tooltip = "";
  export let title = "";
  const Plotly = $plotlyStore;
  let loadingImgExport = false;
  let loadingCsvExport = false;

  const drawWatermark = async (ctx: CanvasRenderingContext2D, scaledWidth: number, scaledHeight: number) => {
    const watermark = await loadImage("assets/images/watermark-lightgray.png");
    ctx.globalCompositeOperation = "source-over";
    const ratio = watermark.height as number / (watermark.width as number);
    const wmX = scaledWidth - (1540);
    const wmY = 40
    const wmWidth = 1500;
    const wmHeight = 1500 * ratio;
    ctx.drawImage(watermark, wmX, wmY, wmWidth, wmHeight);
    ctx.globalCompositeOperation = "destination-over";
  }

  const drawFill = (ctx: CanvasRenderingContext2D, scaledWidth: number, scaledHeight: number) => {
    ctx.beginPath();
    ctx.rect(0, 0, scaledWidth, scaledHeight);
    ctx.fillStyle = "white";
    ctx.fill();
  };

  async function exportImage() {
    loadingImgExport = true;
    const width = plot.clientWidth;
    const height = plot.clientHeight;
    const img = await Plotly.toImage(plot, {
      format: "png",
      width,
      height,
      scale: 10,
    });
    // scaled plot width + 40 px buffer for each side
    const scaledWidth = (width * 10) + 80;
    const scaledHeight = (height * 10) + 80;
    let image = new Image();
    image.src = img;
    image.onload = async () => {
      // create a new canvas
      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');
      canvas.width = scaledWidth;
      canvas.height = scaledHeight;
      // add the watermark
      await drawWatermark(ctx, scaledWidth, scaledHeight);
      // add the plot image
      ctx.drawImage(image, 40, 40);
      // fill background with white
      drawFill(ctx, scaledWidth, scaledHeight);
      const content = canvas.toDataURL("image/png");
      downloadFile(content, `${fileName}.png`);
      loadingImgExport = false;
      trackEvent("export-image");
    };
  }

  function exportCsv() {
    loadingCsvExport = true;
    const groupByTime = (all, node) => {
      const filterValue = node.objClass;
      const newCount = ({ ...all[node.time] }[filterValue] || 0) + node.result;

      return {
        ...all,
        [node.time]: { ...all[node.time], [filterValue]: newCount },
      };
    };
    const timeData = exportData.reduce(groupByTime, {});
    const csvJson = Object.keys(timeData).map((k) => {
      return {
        time: k,
        ...timeData[k],
      };
    });
    const csv = Papa.unparse(csvJson, { newline: "%0A" });
    downloadFile(`data:attachment/csv,${csv}`, `${fileName}.csv`);
    trackEvent("export-csv");
    loadingCsvExport = false;
  }
</script>

<div class="export-wrapper">
  {#if title}
    <h3 style="margin-right:auto;">{title}</h3>
  {/if}
  <div class="buttons">
    {#if tooltip}
      <Tooltip {tooltip} top="45px" right="-120px" />
    {/if}
    {#if fileName}
      <div class="button">
        <Button disabled={loading} on:click={exportImage} loading={loadingImgExport}>
          <div slot="content">export image</div>
        </Button>
      </div>
    {/if}
    {#if exportData}
      <div class="button">
        <Button disabled={loading} on:click={exportCsv} loading={loadingCsvExport}>
          <div slot="content">export CSV</div>
        </Button>
      </div>
    {/if}
  </div>
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .export-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 10px 20px;
    width: 90%;

    .buttons {
      display: flex;
    }
    .button {
      margin: 0 5px;
    }
  }
</style>
