import type { DeviceNode } from "../types/Device";
import { writable, type Writable } from "svelte/store";

export const deviceStore: Writable<DeviceNode[]> = writable([]);

export const resetDeviceStore = () => {
  deviceStore.set([]);
};

export const updateBySerialNo = (
  serialno: string,
  updates: Partial<DeviceNode>
) => {
  deviceStore.update((devices) => {
    const index = devices.findIndex((d) => d.serialno === serialno);
    if (index !== -1) {
      devices[index] = { ...devices[index], ...updates };
    }
    return devices;
  });
};
