<script>
  import { useNavigate, useLocation } from "svelte-navigator";
  import { tokenStore } from "../stores/user";

  export let authRequired = true;
  const navigate = useNavigate();
  const location = useLocation();
  let render = false;

  // if the route requires auth and there is no token or vice versa, redirect
  $: if ((authRequired && !$tokenStore) || (!authRequired && !!$tokenStore)) {
    const redirect = authRequired ? "/login" : "/";
    navigate(redirect, {
      state: { from: $location.pathname },
      replace: true,
    });
  } else {
    render = true;
  }
</script>

{#if render}
  <slot />
{/if}
