<script lang="ts">
  import Button from "./Button.svelte";

  export let text;
</script>

<div class="banner">
  <div class="text">
    {@html text}
  </div>
  <div class="button">
    <Button className="icon" on:click>
      <div style="display:flex;justify-content: center;" slot="content">
        <img
          style="justify-self: center;"
          src="assets/images/close.svg"
          width="20"
          alt="close"
        />
      </div>
    </Button>
  </div>
</div>

<style scoped lang="scss">
  @use "theme.scss";

  .banner {
    overflow: hidden;
    padding: 15px;
    display: flex;
    border-bottom: 1px solid theme.$black;
    position: fixed;
    margin-bottom: 50px;
    width: 100%;
    background-color: theme.$main;
    z-index: 100;
    color: white;
    .text {
      margin-left: auto;
    }
    .button {
      margin-left: auto;
      margin-right: 20px;
      display: flex;
    }
  }
</style>
