<script lang="ts">
  import {
    createClient,
    dedupExchange,
    fetchExchange,
    cacheExchange,
    errorExchange,
    setContextClient,
  } from "@urql/svelte";
  import {
    type RetryExchangeOptions,
    retryExchange,
  } from "@urql/exchange-retry";
  import type { OperationDefinitionNode } from "graphql";
  import { onMount } from "svelte";
  import { Router, Route } from "svelte-navigator";
  import * as Sentry from "@sentry/svelte";
  import { IdleSessionTimeout } from "idle-session-timeout";
  import {
    clearAll,
    getStorage,
    initAPIStorage,
    initLocalStorage,
    setStorage,
  } from "./lib/storage";
  import Login from "./pages/Login.svelte";
  import Dashboard from "./pages/Dashboard.svelte";
  import Header from "./components/Header.svelte";
  import { settingsStore, tokenStore } from "./stores/user";
  import { plotlyStore } from "./stores/plotly";
  import ResetPassword from "./pages/ResetPassword.svelte";
  import { userStore } from "./stores/user";
  import { trackEvent } from "./lib/analytics";
  import SsoPage from "./pages/SsoPage.svelte";
  import { dataVersions } from "./constants/utils";

  $: loggedIn = !!$tokenStore;
  $: plotlyInitialized = false;
  let seenBanner = false;
  const hideBannerDate = new Date("08/01/2023");
  const seenBannerCookie = getStorage("hideBanner", "false") === "true";
  $: shouldShowBanner =
    $settingsStore?.dataVersion === dataVersions[1].value &&
    !seenBanner &&
    !seenBannerCookie &&
    new Date() < hideBannerDate;
  const url = initAPIStorage();
  // automatically log out after 30 minutes of inactivity
  let session = new IdleSessionTimeout(30 * 60 * 1000);
  session.onTimeOut = () => {
    logout();
  };
  session.start();
  const retryExchangeOptions: RetryExchangeOptions = {
    maxNumberAttempts: 4,
    retryIf: (err) => {
      return !!(err && err.networkError);
    },
  };
  const client = createClient({
    url: url + "/graphql",
    fetchOptions: () => {
      return {
        headers: {
          authorization: $tokenStore ? `${$tokenStore}` : "",
        },
      };
    },
    exchanges: [
      dedupExchange,
      cacheExchange,
      // @ts-ignore
      retryExchange(retryExchangeOptions),
      errorExchange({
        onError(error, o) {
          if (error?.message.includes("Invalid JWT")) {
            // expired token
            logout();
          }
          let variables = {};
          const queryVars = o?.variables as Object;
          const node = o?.query?.definitions[0] as OperationDefinitionNode;
          const queryName = node?.name?.value || "unknown query";
          if (queryVars && !Object.keys(queryVars).includes("password")) {
            variables = queryVars;
          }
          Sentry.captureException(error, {
            user: $userStore,
            extra: { variables, queryName },
          });
        },
      }),
      fetchExchange,
    ],
  });
  setContextClient(client);

  onMount(() => {
    initLocalStorage();
  });

  function initPlotly() {
    if (!$plotlyStore) {
      // @ts-ignore
      const plotly = window?.Plotly;
      plotlyStore.set(plotly);
      // pass this bool to dashboard to prevent attempting to
      // load Plots before plotly is initialized
      plotlyInitialized = true;
    }
  }

  function logout() {
    clearAll();
    trackEvent("logout");
    window.location.href = "/login";
  }

  function hideBanner() {
    seenBanner = true;
    setStorage("hideBanner", "true");
  }
</script>

<Router>
  <main>
    <Header {loggedIn} {shouldShowBanner} on:click={hideBanner} />
    <div
      class="app-wrapper"
      style={shouldShowBanner ? "padding-top:150px;" : "padding-top:100px;"}
    >
      <Route path="login/"><Login /></Route>
      <Route path="login/:reset"><Login /></Route>
      <Route path="/">
        <Dashboard {plotlyInitialized} />
      </Route>
      <Route path="reset_password/:token" let:params>
        <ResetPassword token={params.token} />
      </Route>
      <Route path="login-sso">
        <SsoPage />
      </Route>
      <!-- default route -->
      <Route component={Dashboard} />
    </div>
  </main>
</Router>
<svelte:head>
  <script
    src="https://cdnjs.cloudflare.com/ajax/libs/plotly.js/2.12.1/plotly.min.js"
    on:load={initPlotly}
    integrity="sha384-2ANk5s7BiXtH+SPhi1dFRGPDBhIumQAukpE3+etiUKSa8AEHcrOqZGQ09XrhRmYt"
    crossorigin="anonymous"
  ></script>
</svelte:head>

<style>
  .app-wrapper {
    display: flex;
    place-items: center;
    place-content: center;
  }
</style>
