import { gql } from "@urql/svelte";
import type { ZoneCountMetrics } from "../types/ZoneCountMetrics";

export const GET_BEHAVIOR_ZONE_COUNT_METRICS = gql`
  query ZoneCountMetrics(
    $zoneIds: [Int]!
    $startTime: DateTime!
    $endTime: DateTime!
    $interval: String
    $objClasses: [String]!
    $timezone: String
    $before: String
    $after: String
    $first: Int
    $last: Int
    $dataVersion: DataVersionEnum
  ) {
    zoneCountMetrics(
      zoneIds: $zoneIds
      startTime: $startTime
      endTime: $endTime
      interval: $interval
      objClasses: $objClasses
      timezone: $timezone
      before: $before
      after: $after
      first: $first
      last: $last
      dataVersion: $dataVersion
    ) {
      edges {
        node {
          id
          metric
          objClass
          result
          time
        }
      }
    }
  }
`;

export const zoneCountTransformer = (data: ZoneCountMetrics) => {
  return data?.zoneCountMetrics?.edges?.map((e) => e.node) || [];
};
