<script lang="ts">
  import PrivateRouteGuard from "../components/PrivateRouteGuard.svelte";
  import DashContents from "..//components/DashContents.svelte";
  export let plotlyInitialized;
</script>

<PrivateRouteGuard authRequired={true}>
  <slot>
    <DashContents {plotlyInitialized} />
  </slot>
</PrivateRouteGuard>
