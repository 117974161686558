<script lang="ts">
  import Button from "./baseComponents/Button.svelte";
  import Banner from "./baseComponents/Banner.svelte";
  import { useNavigate, useLocation } from "svelte-navigator";
  import { clearAll, setStorage } from "../lib/storage";
  import { trackEvent } from "../lib/analytics";
  import { statusApiStore } from "../stores/app";
  import Modal from "./Modal.svelte";
  import { dataVersions } from "../constants/utils";
  import { settingsStore } from "../stores/user";

  export let loggedIn;
  export let shouldShowBanner = false;
  const navigate = useNavigate();
  const location = useLocation();
  // change `text` to have a different banner message
  const text =
    'Numina\'s data has undergone an upgrade! If you have any questions please <a style="color:white;text-decoration:underline" href="https://meetings.hubspot.com/srajan-bhagat" target="_blank">schedule time with us.</a>';
  $: viewSettingsModal = false;
  // default to NEW data lake
  let dataVersion = dataVersions[1].value;
  settingsStore.subscribe((v) => (dataVersion = v.dataVersion));
  function logout() {
    trackEvent("logout");
    navigate("/login", {
      state: { from: $location.pathname },
      replace: true,
    });
    clearAll();
  }

  const saveSettings = () => {
    setStorage("settings", { dataVersion });
    settingsStore.set({ dataVersion });
    viewSettingsModal = false;
  };
</script>

{#if loggedIn && shouldShowBanner}
  <Banner {text} on:click />
{/if}
<div class="header" style={loggedIn && shouldShowBanner ? "top:50px" : ""}>
  <div class="logo">
    <img
      src="/assets/images/numina_logo_horizontal_color.svg"
      alt="Numina Logo"
      width="200"
    />
  </div>
  <div class="header-right">
    <Button
      className="spaced link"
      style="align-self:center;"
      on:click={() => {
        window.location.href = "mailto:inquiries@numina.co";
      }}><div slot="content">Contact Us</div></Button
    >
    <Button
      className="spaced link"
      style="align-self:center;"
      on:click={() => {
        window.open($statusApiStore, "_blank");
      }}><div slot="content">Status</div></Button
    >
    {#if loggedIn}
      <Button
        className="spaced link"
        style="align-self:center;"
        on:click={() => (viewSettingsModal = true)}
        ><div slot="content">Settings</div></Button
      >
      <Button
        className="spaced link"
        style="align-self:center;"
        on:click={logout}><div slot="content">Logout</div></Button
      >
    {/if}
  </div>
</div>
{#if viewSettingsModal}
  <Modal on:close={() => (viewSettingsModal = false)}>
    <h2 class="modal-header" slot="header">Settings</h2>
    <div slot="body">
      <div class="settings">
        <div class="description">
          These settings will apply to the current dashboard session. Sign out
          to reset.
        </div>
        <div class="setting">
          <label for="show-heatmap">Data Version</label>
          <select
            bind:value={dataVersion}
            name="data-version"
            id="data-version"
          >
            {#each dataVersions as d}
              <option value={d.value}>{d.label}</option>
            {/each}
          </select>
        </div>
        <div class="button">
          <Button on:click={saveSettings}><div slot="content">Save</div></Button
          >
        </div>
      </div>
    </div>
  </Modal>
{/if}

<style scoped lang="scss">
  @use "theme.scss";
  .header {
    overflow: hidden;
    padding: 11px 15px;
    display: flex;
    border-bottom: 1px solid theme.$black;
    position: fixed;
    margin-bottom: 50px;
    width: 100%;
    background-color: white;
    z-index: 100;
    .logo {
      font-size: 25px;
      font-weight: bold;
    }

    .header-right {
      margin-left: auto;
      margin-right: 20px;
      display: flex;
    }
  }

  .settings {
    min-width: 400px;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    .setting {
      margin: 20px;
    }
    label {
      width: 150px;
    }

    select {
      width: 300px;
      border: none;
      font-family: Inter;
      box-shadow: none;
      border-radius: 8px;

      &:hover,
      &:focus,
      &:active {
        outline: 2px solid theme.$main !important;
        border-radius: 8px;
      }
    }

    .button {
      margin: auto;
    }
  }
</style>
