<script lang="ts">
  import type { Metric } from "../../types/Graph";
  import moment from "moment";
  import Button from "../baseComponents/Button.svelte";
  import { formatForViewing } from "../../constants/dateRanges";
  import {
    type DateFilter,
    TimeOfDay,
    type Mode,
    timeOfDayMap,
  } from "../../types/Filters";
  import { trackEvent } from "../../lib/analytics";
  import { exportImage } from "../../lib/utils";

  export let counts: Metric[];
  export let loading = true;
  export let dateRange: DateFilter;
  export let modes: Mode[];
  export let timeOfDay: TimeOfDay;
  export let dayOfWeek: string;

  $: hiddenModes = [];

  const hourToBucket = (hour: number) => {
    if (hour < 5 || hour > 21) return;
    if (hour < 7) return TimeOfDay.EARLY_MORNING;
    if (hour < 9) return TimeOfDay.MORNING_COMMUTE;
    if (hour < 11) return TimeOfDay.MORNING;
    if (hour < 14) return TimeOfDay.NOON;
    if (hour < 17) return TimeOfDay.AFTERNOON;
    if (hour < 19) return TimeOfDay.EVENING_COMMUTE;
    if (hour < 21) return TimeOfDay.EVENING;
  };

  type Timebucket = {
    name: string;
    subtitle: string;
    weekday: {
      [key in Mode]: number;
    };
    weekend: {
      [key in Mode]: number;
    };
  };

  const emptyBucket = {
    pedestrian: 0,
    bicycle: 0,
    motorbike: 0,
    bus: 0,
    car: 0,
    truck: 0,
  };
  // @ts-ignore
  let timebuckets: { [key in TimeOfDay]: Timebucket } = {};
  Object.values(TimeOfDay).forEach((t) => {
    timebuckets[t] = {
      name: timeOfDayMap[t].name,
      subtitle: timeOfDayMap[t].label,
      weekday: { ...emptyBucket },
      weekend: { ...emptyBucket },
    };
  });
  $: total = Object.values(timebuckets).reduce(
    (acc, bucket) =>
      acc +
      Object.keys(bucket.weekday).reduce((acc, key) => {
        if (!hiddenModes.includes(key as Mode)) {
          return acc + bucket.weekday[key];
        }
        return acc;
      }, 0) +
      Object.keys(bucket.weekend).reduce((acc, key) => {
        if (!hiddenModes.includes(key as Mode)) {
          return acc + bucket.weekend[key];
        }
        return acc;
      }, 0),
    0
  );
  function timebucketData(transformedData: Metric[]) {
    transformedData.forEach((value: Metric) => {
      const date = value.time;
      const isWeekend = moment(date).day() > 5;
      const hour = moment(date).hour();
      const timebucket = hourToBucket(hour);
      if (timebucket) {
        timebuckets[timebucket][isWeekend ? "weekend" : "weekday"][
          value.objClass
        ] += value.result;
      }
    });
    return timebuckets;
  }

  function calculateColor(value: number) {
    if (value === 0 || total === 0) {
      return "rgba(86, 187, 128, 0.1)";
    }
    const percent = (value / total + 0.1).toFixed(1);
    return `rgba(86, 187, 128, ${percent})`;
  }

  function exportChartImage() {
    const div = document.querySelector("#chart") as HTMLElement;
    const filename = `activity_by_time_${formatForViewing(
      dateRange.startDate
    )}_${formatForViewing(dateRange.endDate)}`;
    exportImage(
      div,
      filename,
      null,
      div.clientWidth * 2,
      div.clientHeight * 2,
      2
    );

    trackEvent("export-image");
  }

  $: {
    if (counts && !loading) {
      timebuckets = timebucketData(counts);
    }
  }

  function toggleModes(mode: Mode) {
    const idx = hiddenModes.indexOf(mode);
    if (idx > -1) {
      hiddenModes.splice(idx, 1);
    } else {
      hiddenModes.push(mode);
    }
    hiddenModes = hiddenModes;
  }

  const getTimeboxValue = (
    dayModeCounts: { [key in Mode]: number },
    hiddenModes // include as a parameter to trigger reactivity
  ) => {
    const filtered = Object.keys(dayModeCounts)
      .filter((mode) => !hiddenModes.includes(mode as Mode))
      .reduce((acc, mode) => acc + dayModeCounts[mode as Mode], 0);
    return filtered;
  };

  const isActive = (boxDay, boxTime) => {
    return (
      (dayOfWeek === boxDay || dayOfWeek === "All Days") &&
      timeOfDay === boxTime
    );
  };
</script>

<div>
  <div class="header">
    <div class="title">Trend Comparisons</div>
    <div class="swatches">
      {#each modes as mode}
        <Button
          on:click={() => toggleModes(mode)}
          className={"spaced plain small swatch " +
            mode +
            (hiddenModes.includes(mode) ? " hidden" : "")}
        >
          <div slot="content">
            <img
              src="assets/images/{mode}.svg"
              height="25"
              width="25"
              alt="all modes"
            />
          </div>
        </Button>
      {/each}
    </div>
    <Button disabled={loading} on:click={exportChartImage}>
      <div slot="content">export image</div>
    </Button>
  </div>
  <div class="subtitle">
    See how your selected window compares to other time periods.
  </div>
  <div class="box-wrapper" id="chart">
    <table>
      <tr>
        <td>
          <div class="title">Weekday</div>
          <div class="subtitle">(Mon-Fri)</div>
        </td>
        {#each Object.keys(timebuckets) as timebucket}
          <td>
            <div
              class={"square" +
                (isActive("Weekdays", timebucket) ? " active" : "")}
              style="background-color: {calculateColor(
                getTimeboxValue(timebuckets[timebucket].weekday, hiddenModes)
              )}"
            >
              <div class="result">
                {getTimeboxValue(
                  timebuckets[timebucket].weekday,
                  hiddenModes
                ).toLocaleString()}
              </div>
            </div>
          </td>
        {/each}
      </tr>
      <tr>
        <td>
          <div class="title">Weekend</div>
          <div class="subtitle">(Sat-Sun)</div>
        </td>
        {#each Object.keys(timebuckets) as timebucket}
          <td>
            <div
              class={"square" +
                (isActive("Weekends", timebucket) ? " active" : "")}
              style="background-color: {calculateColor(
                getTimeboxValue(timebuckets[timebucket].weekend, hiddenModes)
              )}"
            >
              <div class="result">
                {getTimeboxValue(
                  timebuckets[timebucket].weekend,
                  hiddenModes
                ).toLocaleString()}
              </div>
            </div>
          </td>
        {/each}
      </tr>
      <tr>
        <td />
        {#each Object.keys(timebuckets) as timebucket}
          <td>
            <div class="title">{timebuckets[timebucket].name}</div>
            <div class="subtitle">{timebuckets[timebucket].subtitle}</div>
          </td>
        {/each}
      </tr>
    </table>
  </div>
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .header {
    display: flex;
    color: theme.$black;
    .title {
      font-size: 22px;
      font-weight: normal;
      margin-bottom: 10px;
      margin-right: auto;
      opacity: 0.8;
    }
  }
  .box-wrapper {
    background-color: white;
  }
  table {
    border-collapse: collapse;
    td {
      text-align: center;
      min-width: 85px;
      .result {
        margin-top: 37px;
      }
      .title {
        font-size: 12px;
        font-weight: 600;
        color: theme.$black;
        line-height: 14px;
        max-width: 95px;
      }
      .subtitle {
        font-size: 12px;
        font-weight: 400;
        color: theme.$black;
        line-height: 14px;
      }
    }
  }
  .square {
    width: 95px;
    height: 95px;
    text-align: center;
    border: 1px solid white;

    &.active {
      outline: 2px solid theme.$main;
    }
  }
</style>
