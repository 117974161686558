<script lang="ts">
  import { onMount, onDestroy, createEventDispatcher } from "svelte";
  import { sampleImageUrl } from "../../constants/utils";
  import Loading from "../baseComponents/Loading.svelte";

  export let color = null;
  export let demarcation = [];
  export let loading = false;
  export let sensor;
  export let errorText = "";
  let shapeSelected = false;
  let selectedPoint;
  let dragStart;
  let animationFrame;
  let ctx;
  let canvas;

  const dispatch = createEventDispatcher();

  $: {
    // update canvas when color changes
    if (ctx) {
      ctx.strokeStyle = color;
      ctx.fillStyle = color;
    }
  }

  const draw = () => {
    if (ctx && canvas){
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      drawPath();
      drawPoints();
      animationFrame = window.requestAnimationFrame(draw);
    }
  };

  onMount(() => {
    ctx = canvas.getContext("2d");

    setupCanvas();
    if (demarcation.length) {
      window.requestAnimationFrame(draw);
    }
  });

  $: {
    if (demarcation.length && color) {
      window.requestAnimationFrame(draw);
    }
  }

  onDestroy(() => {
    window.cancelAnimationFrame(animationFrame);
  });

  const getEventCanvasCoordinates = ({ clientX, clientY }) => [
    clientX - canvas.getBoundingClientRect().left,
    clientY - canvas.getBoundingClientRect().top,
  ];

  const dist = (p, q) => Math.sqrt((p[0] - q[0]) ** 2 + (p[1] - q[1]) ** 2);

  const getNearestDemIdx = (coord) => {
    const idx = demarcation.indexOf(
      demarcation.filter((p) => dist(p, coord) <= 15).pop()
    );

    return idx !== -1 ? idx : null;
  };

  const isInShape = (p, shape) => {
    let x = p[0];
    let y = p[1];

    let inside = false;

    for (let i = 0, j = shape.length - 1; i < shape.length; j = i++) {
      let xi = shape[i][0];
      let yi = shape[i][1];

      let xj = shape[j][0];
      let yj = shape[j][1];

      let intersect =
        yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

      if (intersect) inside = !inside;
    }

    return inside;
  };

  function setupCanvas() {
    ctx.lineWidth = 2;
    ctx.fillStyle = color;
    ctx.strokeStyle = color;

    //this.canvas.addEventListener("click", this.handleClick)
    canvas.addEventListener("mousedown", handleMouseDown);
    canvas.addEventListener("mouseup", handleMouseUp);
    canvas.addEventListener("mousemove", handleMouseMove);
  }

  function drawPath() {
    if (!loading && demarcation.length) {
      const start = [demarcation[0][0], demarcation[0][1]];
      const limit = demarcation.length - 1;
      let i = 0;

      ctx.beginPath();
      ctx.moveTo(...start);

      while (i < limit) {
        ctx.lineTo(demarcation[i + 1][0], demarcation[i + 1][1]);
        i++;
      }

      ctx.lineTo(...start);
      ctx.closePath();
      ctx.stroke();
      ctx.globalAlpha = 0.4;
      ctx.fill();
      ctx.globalAlpha = 1;
    }
  }

  function drawPoints() {
    if (!loading) {
      demarcation.forEach((p) => {
        ctx.beginPath();
        ctx.arc(p[0], p[1], 4, 0, 2 * Math.PI);
        ctx.closePath();
        ctx.stroke();
        ctx.fill();
      });
    }
  }

  function handleMouseDown(ev) {
    const coordinates = getEventCanvasCoordinates(ev);

    const demarcationIdx = getNearestDemIdx(coordinates);

    if (demarcationIdx !== null) {
      shapeSelected = false;
      selectedPoint = demarcationIdx;
    }

    if (!shapeSelected && isInShape(coordinates, demarcation)) {
      shapeSelected = true;
      dragStart = getEventCanvasCoordinates(ev);
    }
  }

  function handleMouseUp() {
    selectedPoint = null;
    shapeSelected = false;
    dispatch("move", { demarcation });
  }

  function handleMouseMove(ev) {
    const coords = getEventCanvasCoordinates(ev);

    if (selectedPoint !== null && selectedPoint !== undefined) {
      demarcation[selectedPoint] = coords;
      return;
    }

    if (shapeSelected === true) {
      const delta = [coords[0] - dragStart[0], coords[1] - dragStart[1]];
      demarcation = demarcation.map((p) => [p[0] + delta[0], p[1] + delta[1]]);

      dragStart = coords;
    }
  }
</script>

<div class="image-wrapper">
  <div
    class="image-container"
    style="background-image: url({sampleImageUrl(
      sensor?.serialno || ''
    )}); background-size: cover;"
  />
  <canvas bind:this={canvas} class="zone-canvas" width="640" height="480" />
  {#if loading}
    <div class="loading">
      <Loading />
    </div>
  {/if}
  {#if errorText}
    <div class="error">
      <div class="error-content">
        <p>{errorText}</p>
      </div>
    </div>
  {/if}
</div>

<style scoped lang="scss">
  @use "theme.scss";
  .image-wrapper {
    width: 640px;
    height: 480px;
    position: relative;
    vertical-align: top;
    background-size: 100%;
    align-items: center;
    overflow-x: hidden;
  }

  .image-container {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
    z-index: 0;
  }

  .zone-canvas {
    position: absolute;
    z-index: 1;
  }

  .loading,
  .error {
    position: absolute;
    width: 640px;
    height: 480px;
    background-color: theme.$gray-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
  }
  .error-content {
    border-radius: 8px;
    min-width: 150px;
    padding: 5px;
    background-color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
</style>
