<script lang="ts">
  import CountChart from "../dataVisualization/CountChart.svelte";
  import PieChart from "../dataVisualization/PieChart.svelte";
  import type { Metric } from "../../types/Graph";
  import type { DateFilter, Interval } from "../../types/Filters";
  import type { DeviceNode } from "../../types/Device";
  import type { Organization } from "../../types/Organization";

  export let counts: Metric[];
  export let interval: Interval;
  export let sensor: DeviceNode;
  export let dateRange: DateFilter;
  export let organization: Organization;
  export let loading = true;
</script>

<div class="graph-container">
  <div class="row">
    <div class="graph-wrapper count">
      <CountChart
        {counts}
        {sensor}
        {dateRange}
        {interval}
        {organization}
        {loading}
      />
    </div>
    <div class="graph-wrapper pie">
      <PieChart {counts} {sensor} {dateRange} {organization} {loading} />
    </div>
  </div>
</div>

<style lang="scss">
  .graph-container {
    display: block;
  }

  .row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 30px 0;
  }

  .graph-wrapper {
    &.count {
      min-width: 800px;
      margin-right: 50px;
    }
  }
</style>
