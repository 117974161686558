import "./app.scss";
import App from "./App.svelte";
import * as Sentry from "@sentry/svelte";
import { BrowserTracing } from "@sentry/tracing";

// Initialize the Sentry SDK
Sentry.init({
  dsn: "https://4d25bdd1b2e4408082b24b58a96ec57e@o1235335.ingest.sentry.io/4504243864141824",
  integrations: [new BrowserTracing()],
  environment: import.meta.env.MODE,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

const app = new App({
  target: document.getElementById("app"),
});

export default app;
