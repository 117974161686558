import moment from "moment";
import type { DateFilter } from "../types/Filters";

const dateFormat = "YYYY-MM-DDTHH:mm:ss";
const now = () => {
  return moment().format(dateFormat);
};
export const dateRanges: DateFilter[] = [
  {
    label: "Today",
    startDate: moment().startOf("day").format(dateFormat),
    endDate: now(),
  },
  {
    label: "Yesterday",
    startDate: moment().subtract(1, "d").startOf("day").format(dateFormat),
    endDate: moment().subtract(1, "d").endOf("day").format(dateFormat),
  },
  {
    label: "Past Week",
    startDate: moment().subtract(1, "w").startOf("day").format(dateFormat),
    endDate: now(),
  },
  {
    label: "Past Month",
    startDate: moment().subtract(1, "M").startOf("day").format(dateFormat),
    endDate: now(),
  },
  {
    label: "Past 6 Months",
    startDate: moment().subtract(6, "M").startOf("day").format(dateFormat),
    endDate: now(),
  },
];

export const formatForDateRange = (date: string | Date, start: boolean) => {
  if (start) {
    return moment(date).startOf("day").format(dateFormat);
  } else {
    return moment(date).endOf("day").format(dateFormat);
  }
};

export const formatForViewing = (date: string) => {
  return moment(date).format("MM-DD-YY");
};
