<script lang="ts">
  import PrivateRouteGuard from "../components/PrivateRouteGuard.svelte";
  import ResetPassword from "../components/login/ResetPassword.svelte";
  export let token;
</script>

<!-- svelte-ignore a11y-click-events-have-key-events -->
<!-- svelte-ignore a11y-missing-attribute -->
<PrivateRouteGuard authRequired={false}>
  <slot>
    <div class="reset-wrapper">
      <ResetPassword {token} />
    </div>
  </slot>
</PrivateRouteGuard>

<style scoped lang="scss">
  @use "theme.scss";
</style>
