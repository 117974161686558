<script lang="ts">
  import { onMount } from "svelte";
  import type { BehaviorZoneNode } from "../types/BehaviorZone";
  import type { DeviceNode } from "../types/Device";
  import { dataVersions, sampleImageUrl } from "../constants/utils";
  import {
    dateFilterStore,
    sensorFilterStore,
    intervalFilterStore,
    modeFilterStore,
    trendsFilterStore,
  } from "../stores/filters";
  import HeatMaps from "./dataVisualization/HeatMaps.svelte";
  import { getContextClient, queryStore } from "@urql/svelte";
  import { GET_HEAT_MAP, feedHeatmapTransformer } from "../queries/getHeatMaps";
  import {
    GET_ZONE_HEAT_MAP,
    zoneHeatmapTransformer,
  } from "../queries/getZoneHeatMaps";
  import type { Organization } from "../types/Organization";
  import Loading from "./baseComponents/Loading.svelte";
  import Button from "./baseComponents/Button.svelte";
  import { ZoneType, dayOfWeekMap, AnalysisType } from "../types/Filters";
  import { getErrorMessage } from "../lib/utils";
  import { settingsStore } from "../stores/user";
  export let organization: Organization;
  export let previewZone: BehaviorZoneNode | undefined;
  export let sensor: DeviceNode;
  export let behaviorZones = [];
  export let zoneType: ZoneType;
  export let analysisType: AnalysisType;
  $: dateRange = $dateFilterStore;
  $: sensor = $sensorFilterStore;
  $: interval = $intervalFilterStore;
  $: modes = $modeFilterStore;
  $: trends = $trendsFilterStore;
  $: dataVersion = $settingsStore?.dataVersion;
  $: sampleImgUrl = sampleImageUrl(sensor?.serialno || "");
  $: shouldShowAnalysis = false;
  let zoneCanvas;
  let zoneCtx;
  let heatMaps = [];
  let showHeatMap = null;
  let variables;

  onMount(() => {
    zoneCtx = zoneCanvas.getContext("2d");
  });
  $: {
    // if not currently viewing analysis
    if (!shouldShowAnalysis) {
      // if the current canvas is rendered
      if (!!zoneCtx && !!zoneCanvas) {
        // if hovering over a zone
        if (!!previewZone) {
          drawZone(previewZone);
          // if leaving hover
        } else if (!previewZone) {
          zoneCtx.clearRect(0, 0, zoneCanvas.width, zoneCanvas.height);
          if (behaviorZones.length) {
            behaviorZones.forEach((zone) => {
              drawZone(zone);
            });
          }
        }
      }
    }
  }
  $: {
    shouldShowAnalysis = !!(sensor && modes?.length && interval && dateRange);
    // don't attempt to run query if filters aren't populated
    if (shouldShowAnalysis) {
      heatMapData.resume();
    } else {
      heatMapData.pause();
      heatMaps = [];
      showHeatMap = null;
    }
  }
  const client = getContextClient();
  $: query =
    analysisType === AnalysisType.TURNS ? GET_ZONE_HEAT_MAP : GET_HEAT_MAP;
  $: {
    variables = {
      startTime: dateRange.startDate,
      endTime: dateRange.endDate,
      objClasses: modes,
      timezone: organization.timezone,
      dataVersion,
    };
    if (trends) {
      if (trends.dayOfWeek) {
        variables.days = dayOfWeekMap[trends.dayOfWeek];
      }
      if (trends.timeOfDay) {
        variables.window = trends.timeOfDay;
      }
    }
    if (analysisType === AnalysisType.TURNS) {
      variables.zoneIds = behaviorZones.map((zone) => zone.rawId);
    } else {
      variables.serialno = sensor?.serialno;
    }
  }
  $: heatMapData = queryStore({
    client,
    query,
    pause: true,
    variables,
  });
  $: {
    if ($heatMapData.data && !$heatMapData.data.error) {
      const transformer =
        analysisType === AnalysisType.TURNS
          ? zoneHeatmapTransformer
          : feedHeatmapTransformer;
      heatMaps = transformer($heatMapData.data);
    }
  }

  function drawZone(zone) {
    if (zoneType === ZoneType.SCREENLINE) {
      // draw the line
      zoneCtx.globalAlpha = 1;
      const points = zone.demarcation;
      zoneCtx.strokeStyle = zone.color;
      zoneCtx.lineWidth = 4;
      zoneCtx.beginPath();
      zoneCtx.moveTo(...points[0]);
      zoneCtx.lineTo(...points[1]);
      zoneCtx.stroke();
    } else {
      // draw the polygon
      zoneCtx.globalAlpha = 0.3;
      zoneCtx.fillStyle = zone.color;
      zoneCtx.beginPath();
      const points = [...zone.demarcation];
      const firstPoint = points.pop();
      zoneCtx.moveTo(...firstPoint);
      points.forEach((point) => {
        zoneCtx.lineTo(...point);
      });
      zoneCtx.closePath();
      zoneCtx.fill();
    }
  }
  function retryHeatMapQuery() {
    queryStore({
      client,
      query,
      variables,
      requestPolicy: "network-only",
    });
  }
</script>

<div class="image-wrapper">
  <div
    class="image-container"
    style="background-image: url({sampleImgUrl});background-size: cover; opacity:{shouldShowAnalysis
      ? 0.85
      : 1};"
  />
  <canvas
    bind:this={zoneCanvas}
    class={(shouldShowAnalysis ? "hidden " : "visible ") + "zone-canvas"}
    width="640"
    height="480"
  />
  <div class={(shouldShowAnalysis ? "visible " : "hidden ") + "maps"}>
    {#if $heatMapData.fetching || $heatMapData.stale}
      <div class="loading">
        <Loading />
      </div>
    {:else if $heatMapData.error}
      <div class="error">
        <div class="error-content">
          <p>{getErrorMessage($heatMapData.error)}</p>
          <Button className="outlined" on:click={retryHeatMapQuery}>
            <div slot="content">Try Again</div>
          </Button>
        </div>
      </div>
    {/if}
    <div>
      <HeatMaps
        {heatMaps}
        {behaviorZones}
        {showHeatMap}
        {shouldShowAnalysis}
        {dateRange}
        {sensor}
        {previewZone}
        {zoneType}
        {modes}
        {analysisType}
      />
    </div>
  </div>
</div>

<style lang="scss" scoped>
  @use "theme.scss";
  .image-wrapper {
    position: relative;
    background-size: 100%;
    display: flex;
    align-items: center;
  }
  .image-container {
    width: 640px;
    height: 480px;
    margin-top: 90px;
    padding: 0;
    position: absolute;
    box-sizing: border-box;
    display: inline-block;
    overflow: hidden;
  }
  .hidden {
    visibility: hidden;
  }
  .visible {
    visibility: visible;
  }
  .zone-canvas {
    position: absolute;
    z-index: 1;
    top: 90px;
  }
  .maps {
    position: relative;
  }
  .loading,
  .error {
    position: absolute;
    width: 640px;
    height: 480px;
    background-color: theme.$gray-out;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 3;
    top: 90px;
    .error-content {
      border-radius: 8px;
      padding: 5px;
      background-color: white;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
</style>
